import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React from 'react';
import { EmailProps } from './email-props';

export class Email extends React.Component<EmailProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div id="ec-email-parent-container" className='field-parent-container'>
        {
          this.props.editEmail
            ?
            <div id="ec-edit-email-container" className='profile-input-container'>
              <TextField
                id="ec-email-input"
                label="Email"
                variant="outlined"
                value={this.props.email}
                onChange={(event) => void this.props.emailChanged(event.target.value)}
                error={!!this.props.emailErrorMessage}
                helperText={this.props.emailErrorMessage} />
            </div>
            :
            <div id="ec-email-container" className='static-field-container'>
              <div><strong>Email:</strong> {this.props.staticEmail}</div>
            </div>
        }
        <div id="ec-email-btn-container" className='edit-btn-container'>
          {
            this.props.editEmail
              ?
              <Button
                id="ec-save-edit-btn"
                className='edit-mode-btn'
                variant='contained'
                onClick={() => void this.props.emailSaved()}
                disabled={!!this.props.emailErrorMessage || this.props.email === this.props.staticEmail}>
                Save
              </Button>
              : <></>
          }
          <Button
            id="ec-edit-email-btn"
            className={this.props.editEmail ? 'edit-mode-btn' : ''}
            onClick={() => void this.props.editButtonClicked()}>
            { this.props.editEmail ? 'Cancel' : 'Edit' }
          </Button>
        </div>
      </div>
    );
  }
}
