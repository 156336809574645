
export const maxLength = (length: number, errorMessage = `Please enter a value no longer than ${length} characters in length.`) => {
  return (text: string) => { return text.length > length ? errorMessage : ''; };
};

export const minLength = (length: number, errorMessage = `Please enter a value at least ${length} character${length > 1 ? 's' : ''} in length.`) => {
  return (text: string) => { return text.length < length ? errorMessage : ''; };
};

export const regEx = (expression: RegExp, errorMessage: string) => {
  return (text: string) => { return expression.test(text) ? '' : errorMessage; };
};

export const min = (n: number, errorMessage = `Please provide a number no less than ${n}.`) => {
  return (text: string) => {
    const x = parseInt(text, 10);

    return x < n ? errorMessage : '';
  };
};

export const max = (n: number, errorMessage = `Please provide a number no greater than ${n}.`) => {
  return (text: string) => {
    const x = parseInt(text, 10);

    return x > n ? errorMessage : '';
  };
};


const validateInput = (text: string, validators: {(data: string): string;}[]) => {
  if (validators) {  
    for (const v of validators) {
      const errorMessage = v(text);

      if (errorMessage) {
        return errorMessage;
      }
    }
  }

  return '';
};

export default validateInput;