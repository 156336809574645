import { createContext } from 'react';

export interface AppContextObject {
  isLoggedIn: boolean,
  setIsLoggedIn: (value: boolean) => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const AppContext = createContext<AppContextObject>({ isLoggedIn: false, setIsLoggedIn: () => {} });

export default AppContext;
