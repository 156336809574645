import React from 'react';
import ReactDOM from 'react-dom';
import './RoutePageItem.scss';
import { getContentItemLabel, getContentTypeIcon } from '../../../models/user-content/enum/content-type';
import { RoutePageItemState } from './route-page-item-state';
import { ConfirmModal } from '../../../common/components/ConfirmModal/ConfirmModal';
import { UserRoutePageItem } from '../../../models/user-route-page/user-route-page-item';
import { USERNAME } from '../../../common/cookies';
import AxiosSingleton from '../../../common/web/axios-singleton';
import { LoadingOverlay } from '../../../common/components/LoadingOverlay/LoadingOverlay';
import Button from '@mui/material/Button';
import { DraggableWrapperProps } from '../../../common/components/drag-and-drop/DraggableWrapper/draggable-wrapper-props';
import { RoutePageItemForm } from '../common/RoutePageItemForm/RoutePageItemForm';
import ContentContext from '../../../common/context/ContentContext';
import { ReactComponent as DragHandleIcon } from '../../../assets/icons/drag_handle.svg';
import { ReactComponent as MoreHorizIcon } from '../../../assets/icons/more_horiz.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export class RoutePageItem extends React.Component<DraggableWrapperProps, RoutePageItemState> {
  constructor(props: DraggableWrapperProps) {
    super(props);

    this.state = {
      showOverlay: false,
      showEditForm: false,
      showDeleteModal: false,
      showMobileMenu: false,
      contentItem: this.props.contentItem
    };

    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  handleDeleteModalClose(confirmed: boolean) {
    this.setState({ showDeleteModal: false });
    if (confirmed) {
      const username = localStorage.getItem(USERNAME);

      if (username) {
        this.setState({ showOverlay: true });
        const axios = AxiosSingleton.get();
        const item = this.props.item as UserRoutePageItem;

        axios.delete(`users/${username}/route-page-items/${item.routePageItemID}`).then((response) => {
          this.setState({ showOverlay: false });
          if (this.props.onDelete && response.status === 204) {
            this.props.onDelete(item.routePageItemID);
          }
        }, () => {
          this.setState({ showOverlay: false });
        });
      }
    }
  }

  toggleMobileMenu(event?: React.MouseEvent<HTMLButtonElement>, callback?: () => void) {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
    if (event) {
      this.setState({ mobileMenuAnchorElement: event.currentTarget });
    }

    if (callback) {
      callback();
    }
  }

  render() {
    return (
      <div
        className={['route-page-item-container', this.props.isDragging ? 'dragging' : ''].join(' ')}
        ref={this.props.moveableRef}>
        {
          this.state.showOverlay
            ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
            : <></>
        }
        {
          this.state.showDeleteModal
            ?
            ReactDOM.createPortal(
              <ConfirmModal
                message='Are you sure you want remove this link from your Route Page?'
                confirmText='Yes'
                declineText='No'
                onClose={(confirmed) => { this.handleDeleteModalClose(confirmed); }} />,
              document.getElementById('modal-portal-container') as HTMLElement
            )
            : <></>
        }
        <div className='route-page-item-btn-container'>
          <DragHandleIcon className='item-drag-icon'/>
          <Button className='route-page-item-edit-btn desktop' onClick={() => this.setState({ showEditForm: !this.state.showEditForm })}>
            { this.state.showEditForm ? 'Cancel' : 'Edit' }
          </Button>
          {
            this.state.showEditForm
              ? undefined
              :
              <Button className='route-page-item-delete-btn desktop' onClick={() => { this.setState({ showDeleteModal: true }); }}>
                Delete
              </Button>
          }
          <Button
            className='mobile mobile-menu-btn-icon'
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (!this.state.showEditForm) {
                this.toggleMobileMenu(event);
              } else {
                this.setState({ showEditForm: false });
              }
            }}>
            { this.state.showEditForm ? 'Cancel' : <MoreHorizIcon /> }
          </Button>
          <Menu
            anchorEl={this.state.mobileMenuAnchorElement}              
            className='mobile'
            open={this.state.showMobileMenu}
            onClose={() => void this.toggleMobileMenu()}>
            <MenuItem onClick={() => void this.toggleMobileMenu(undefined, () => void this.setState({ showEditForm: true }))}>
              Edit
            </MenuItem>
            <MenuItem onClick={() =>  void this.toggleMobileMenu(undefined, () => void this.setState({ showDeleteModal: true }))}>
              Delete
            </MenuItem>
          </Menu>
        </div>
        {
          this.state.contentItem
            ?
            this.state.showEditForm
              ?
              <ContentContext.Consumer>
                {
                  ctx => (
                    <RoutePageItemForm
                      routePageItem={this.props.item as UserRoutePageItem}
                      order={this.props.item.order}
                      contentItems={ctx.contentItems || []}
                      onRoutePageItemProvisioned={(item) => {
                        this.setState({ showEditForm: false });
                        if (item && this.props.onEdit) {
                          this.props.onEdit(item);
                        }
                      }}/>
                  )
                }
              </ContentContext.Consumer>
              :
              <div className='content-container'>
                <div className='content-icon'>
                  {
                    this.state.contentItem
                      ?
                      getContentTypeIcon(
                        this.state.contentItem.contentType,
                        undefined,
                        {width: 40, height: 40, marginLeft: '2px', marginRight: '2px'}
                      )
                      : undefined
                  }
                </div>
                <div className='item-label'>{ (this.props.item as UserRoutePageItem).label || getContentItemLabel(this.state.contentItem) }</div>
              </div>
            :
            <h3 className='missing-content'>
              Oops! Looks like the content for this link has been deleted or there was an issue fetching it.
            </h3>
        }
      </div>
    );
  }
}
