import React from 'react';
import ReactDOM from 'react-dom';
import AppContext from '../../common/context/AppContext';
import { Navigate, useParams } from 'react-router-dom';
import { PasswordResetProps } from './password-reset-props';
import './PasswordReset.scss';
import { PasswordResetState } from './password-reset-state';
import AxiosSingleton from '../../common/web/axios-singleton';
import TextField from '@mui/material/TextField';
import validateInput, { maxLength, regEx } from '../../common/validation/input-validators';
import { PASSWORD_REGULAR_EXPRESSION } from '../../common/validation/regular-expressions';
import { PASSWORD_ERROR_MESSAGE } from '../../common/validation/constants';
import Button from '@mui/material/Button';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { AxiosError } from 'axios';
import { ConfirmModal } from '../../common/components/ConfirmModal/ConfirmModal';
import { CONTENT_ROUTE } from '../../common/app-constants';

export const PasswordResetWrapper = () => {
  const params = useParams();
  
  return (<PasswordReset username={params.username || ''} resetId={params.resetId || ''} />);
};


class PasswordReset extends React.Component<PasswordResetProps, PasswordResetState> {
  private PASSWORD_VALIDATORS = [
    regEx(PASSWORD_REGULAR_EXPRESSION, PASSWORD_ERROR_MESSAGE),
    maxLength(16)
  ];
  private GENERAL_ERROR_MESSAGE = 'An error occurred. Please try again later.';

  constructor(props: any) {
    super(props);

    this.state = {
      lockInput: false,
      showModal: false,
      passwordResetSuccess: false,
      validResetNotFound: false,
      navigateToLoginHelp: false,
      navigateToLogin: false
    };

    this.handleInput = this.handleInput.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  handleInput(input: { password?: string, confirmPassword?: string }) {
    if (input.password || input.password === '') {
      const newState: any = {
        password: input.password,
        passwordErrorMessage: validateInput(input.password, this.PASSWORD_VALIDATORS)
      };

      if (this.state.confirmPassword) {
        newState['confirmPasswordErrorMessage'] = this.state.confirmPassword === input.password ? '' : 'Passwords do not match.';
      }

      this.setState(newState);
    } else {
      this.setState({
        confirmPassword: input.confirmPassword,
        confirmPasswordErrorMessage: input.confirmPassword === this.state.password ? '' : 'Passwords do not match.'
      });
    }
  }

  resetPassword() {
    if (this.props.username && this.props.resetId) {
      this.setState({ lockInput: true });
      const axios = AxiosSingleton.get();

      const request = {
        username: this.props.username,
        resetId: this.props.resetId,
        newPassword: this.state.password
      };

      axios.put('login-help/password-reset', request).then(() => {
        this.setState({ lockInput: false, showModal: true, modalText: 'Password reset successfully.', passwordResetSuccess: true });
      }, (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 409) {
          this.setState({
            lockInput: false,
            showModal: true,
            modalText: 'Your password reset either was not found or has expired. Please request a new one.',
            validResetNotFound: true
          });
        } else {
          this.setState({
            lockInput: false,
            showModal: true,
            modalText: this.GENERAL_ERROR_MESSAGE
          });
        }
      });
    } else {
      this.setState({
        showModal: true,
        modalText: this.GENERAL_ERROR_MESSAGE
      });
    }
  }

  render() {
    return (
      <AppContext.Consumer>
        {
          ctx => (
            ctx.isLoggedIn
              ? <Navigate to={CONTENT_ROUTE}/>
              :
              <div id="prc-password-reset-container">
                {
                  this.state.navigateToLoginHelp
                    ? <Navigate to='/login-help' />
                    : undefined
                }
                {
                  this.state.navigateToLogin
                    ? <Navigate to='/login' />
                    : undefined
                }
                {
                  this.state.showModal
                    ?
                    ReactDOM.createPortal(
                      <ConfirmModal
                        message={this.state.modalText || this.GENERAL_ERROR_MESSAGE}
                        confirmText='Ok'
                        onClose={(confirmed) => {
                          if (confirmed && this.state.validResetNotFound) {
                            this.setState({ navigateToLoginHelp: true });
                          } else if ( confirmed && this.state.passwordResetSuccess ) {
                            this.setState({ navigateToLogin: true });
                          } else {
                            this.setState({ showModal: false });
                          }
                        }}/>,
                      document.getElementById('modal-portal-container') as HTMLElement
                    )
                    : undefined
                }
                <TextField id="prc-password-input"
                  className='input'
                  label="Password"
                  variant='outlined'
                  value={this.state.password || ''}
                  onChange={(event) => void this.handleInput({password: event.target.value})}
                  error={!!this.state.passwordErrorMessage}
                  type={'password'}
                  disabled={this.state.lockInput}
                  helperText={this.state.passwordErrorMessage || ''}/>
                <TextField id="prc-confirm-password-input"
                  className='input'
                  label="Confirm password"
                  variant='outlined'
                  value={this.state.confirmPassword || ''}
                  onChange={(event) => void this.handleInput({confirmPassword: event.target.value})}
                  error={!!this.state.confirmPasswordErrorMessage}
                  type={'password'}
                  disabled={this.state.lockInput}
                  helperText={this.state.confirmPasswordErrorMessage || ''}/>

                <Button id="prc-btn"
                  disabled={!this.state.password || !this.state.confirmPassword
                    || !!this.state.passwordErrorMessage || !!this.state.confirmPasswordErrorMessage || this.state.lockInput}
                  variant='contained'
                  onClick={() => void this.resetPassword()}>
                  { this.state.lockInput ? <LoadingSpinner size={15} /> : 'Set new password' }
                </Button>
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}
