import React, { CSSProperties, ReactElement } from 'react';
import './Products.scss';
import { CDN_URL, PRODUCTS_SECTION_ID } from '../../../common/app-constants';
import { ContentIcon } from './ContentIcon/ContentIcon';
import { ReactComponent as TwitchWhite } from '../../../assets/icons/twitch_white.svg';
import { ReactComponent as Patreon } from '../../../assets/icons/patreon_currentcolor.svg';
import { ReactComponent as YouTubeWhite } from '../../../assets/icons/youtube_white.svg';
import { ReactComponent as TikTok } from '../../../assets/icons/tiktok_color_white.svg';
import { ProductsProps } from './products-props';

export class Products extends React.Component<ProductsProps> {
  constructor(props: ProductsProps) {
    super(props);
  }

  buildContentIcon(key: string, backgroundStyles: CSSProperties, classes: string[], icon: JSX.Element) {
    return (
      <ContentIcon
        key={key}
        backgroundStyles={backgroundStyles}
        className={['move-right-animation'].concat(classes).join(' ')}
        icon={icon}/>
    );
  }

  render() {

    const cdnURL = (CDN_URL);
    const iconCount = 4;
    const delays: { delay: string, rowClass: string }[] = [];

    let delay = 0;
    for (let i = 0; i < iconCount; i++) {
      delays.push({ delay: `${delay}s`, rowClass: i % 2 === 0 ? 'top-row' : 'bottom-row' });
      delay += 4;
    }

    for (let i = 0; i < delays.length; i++) {
      const j = Math.floor(Math.random() * 4);

      const swap = delays[i];
      delays[i] = delays[j];
      delays[j] = swap;
    }

    let i = 0;

    const contentIcons: ReactElement[] = [
      this.buildContentIcon(
        'twitch',
        { backgroundColor: '#9146ff', animationDelay: delays[i].delay},
        ['move-right-animation', delays[i++].rowClass],
        <TwitchWhite />
      ),
      this.buildContentIcon(
        'youtube',
        { backgroundColor: '#FF0000', animationDelay: delays[i].delay},
        ['move-right-animation', delays[i++].rowClass],
        <YouTubeWhite />
      ),
      this.buildContentIcon(
        'tiktok',
        { backgroundColor: 'black', animationDelay: delays[i].delay},
        ['move-right-animation', delays[i++].rowClass],
        <TikTok />
      ),
      this.buildContentIcon(
        'patreon',
        { backgroundColor: 'black', color: 'white', animationDelay: delays[i].delay},
        ['move-right-animation', delays[i++].rowClass],
        <Patreon />
      ) 
    ];



    return (
      <div id={PRODUCTS_SECTION_ID} ref={this.props.productsRef}>
        <div id="pc-reroute-url-container" className='sub-container'>
          <div>
            <h2><span>Reroute URL</span>: Content platform-based conditional redirects.</h2>
          </div>
          <div>
            <p>Direct your community to your most relevant content the moment they click that link.</p>
          </div>
          <div id="pc-graphic-container">
            <img src={cdnURL + 'img/reroute-graphic.png'} alt="Reroute URL graphic"/>
            { contentIcons }
          </div>
        </div>
        <div id="pc-route-page-container" className='sub-container'>
          <div id="pc-background-block"></div>
          <div>
            <h2><span>Route Page</span>: A <span>personal website</span> for you, by you.</h2>
          </div>
          <div>
            <p>Customize a Route Page to stand out and showcase your platforms.</p>
          </div>
          <img src={cdnURL + 'img/route-page-example.png'} alt='Reroute Route Page example' />
        </div>
      </div>
    );
  }
}
