import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { TOKEN, USERNAME } from '../cookies';

let instance: AxiosInstance | undefined;

const getInstance = () => {
  if (!instance) {
    instance =  axios.create({
      baseURL: process.env.REACT_APP_HOME_API_URL
    });

    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem(TOKEN);

      if (token) {
        config.headers.setAuthorization('Bearer ' + token);
      }

      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return Promise.resolve(response); 
      },
      (error: AxiosError) => {
        if (error && error.response && error.response.status === 401 && !window.location.href.endsWith('/login')) {
          localStorage.setItem(TOKEN, '');
          localStorage.setItem(USERNAME, '');
          window.location.href = '/login';
        }

        return Promise.reject(error);
      }
    );
  }

  return instance;
};

const AxiosSingleton = {
  get: () => getInstance()
};

export default AxiosSingleton;
