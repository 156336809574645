import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import update from 'immutability-helper';
import { AxiosError, AxiosResponse } from 'axios';
import React, { RefObject, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { Navigate } from 'react-router-dom';
import { LoadingOverlay } from '../../common/components/LoadingOverlay/LoadingOverlay';
import AppContext from '../../common/context/AppContext';
import ContentContext from '../../common/context/ContentContext';
import { USERNAME } from '../../common/cookies';
import AxiosSingleton from '../../common/web/axios-singleton';
import { UserRule } from '../../models/user-rule/user-rule';
import { RulesForm } from './common/RulesForm/RulesForm';
import { RulesState } from './rules-state';
import './Rules.scss';
import { ReactComponent as OpenInNewIcon } from '../../assets/icons/open_in_new.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ReactComponent as ContentCopy } from '../../assets/icons/content_copy.svg';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd-multi-backend';
import { RulesProps } from './rules-props';
import { UserContent } from '../../models/user-content/user-content';
import { JSONPatchOperation } from '../../models/common/web/json-patch-operation';
import { Draggable } from '../../models/common/drag-and-drop/draggable';
import { DraggableWrapper } from '../../common/components/drag-and-drop/DraggableWrapper/DraggableWrapper';
import { Rule } from './Rule/Rule';
import { DraggableWrapperProps } from '../../common/components/drag-and-drop/DraggableWrapper/draggable-wrapper-props';
import { Identifier } from 'dnd-core';
import { DragLayer } from '../../common/components/drag-and-drop/DragLayer/DragLayer';
import { DRAGGABLE_RULE_TYPE } from '../../common/app-constants';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { useRerouteURL } from '../../common/hooks/use-reroute-url';
import { UserRerouteURL } from '../../models/user-reroute-url/user-reroute-url';

export const RulesWrapper = () => {
  const [rules, setRules] = useState<UserRule[]>([]);
  const [contentItems, setContentItems] = useState<UserContent[]>([]);
  const [fetchesComplete, setFetchesComplete] = useState<boolean>(false);

  const [rerouteURL, setRerouteURL] = useRerouteURL();

  const moveRule = useCallback(
    (dragIndex: number | null, hoverIndex: number | null) => {
      if (dragIndex === null || hoverIndex === null) {
        setRules((previousRules: UserRule[]) => {
          const newRules = previousRules.map(x => x);
          newRules.sort((a, b) => {
            return a.order - b.order;
          });
          newRules.forEach(x => x.index = x.order);

          return newRules;
        });
      } else {
        setRules((previousRules: UserRule[]) => {
          const newRules = update(previousRules, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, previousRules[dragIndex] as UserRule],
            ],
          });
    
          newRules.forEach((x, i) => x.index = i);
    
          return newRules;
        });
      }
    },
    []
  );

  const axios = AxiosSingleton.get();
  const username = localStorage.getItem(USERNAME);

  if (username && !fetchesComplete) {
    Promise.allSettled(
      [
        axios.get(`users/${username}/content`),
        axios.get(`users/${username}/rules`)
      ]
    ).then((results: PromiseSettledResult<AxiosResponse<any, any>>[]) => {
      const contentFetchResult = results[0];

      if (contentFetchResult.status === 'fulfilled' && contentFetchResult.value && contentFetchResult.value.data && contentFetchResult.value.data.embedded && contentFetchResult.value.data.embedded.length) {
        setContentItems(contentFetchResult.value.data.embedded);
      } else {
        console.log((contentFetchResult as PromiseRejectedResult).reason.response.data);
      }

      const rulesFetchResult = results[1];
      if (rulesFetchResult.status === 'fulfilled' && rulesFetchResult.value && rulesFetchResult.value.data && rulesFetchResult.value.data.embedded && rulesFetchResult.value.data.embedded.length) {
        const rules: UserRule[] = rulesFetchResult.value.data.embedded;
        rules.forEach((x, i) => x.index = i);
        setRules(rules);
      } else {
        console.log((rulesFetchResult as PromiseRejectedResult).reason.response.data);
      }

      setFetchesComplete(true);
    });
  }

  return (
    <Rules
      rerouteURL={rerouteURL}
      setRerouteURL={(rerouteURL: UserRerouteURL) => setRerouteURL(rerouteURL)}
      moveRule={moveRule}
      rules={rules}
      contentItems={contentItems}
      setRules={(rules: UserRule[]) => setRules(rules)}
      fetchesComplete={fetchesComplete} />
  );
};


class Rules extends React.Component<RulesProps, RulesState> {
  private MAX_RULE_COUNT = 6;

  constructor(props: any) {
    super(props);

    this.state = {
      showAddRule: false,
      showOverlay: false,
      showCopiedTooltip: false
    };

    this.handleRuleProvisioning = this.handleRuleProvisioning.bind(this);
    this.handleRuleEdit = this.handleRuleEdit.bind(this);
    this.handleRuleDeletion = this.handleRuleDeletion.bind(this);
    this.updateRuleOrders = this.updateRuleOrders.bind(this);
    this.updateRerouteURL = this.updateRerouteURL.bind(this);
  }

  get showAddRule() {
    return this.state.showAddRule && this.props.rules.length < this.MAX_RULE_COUNT;
  }

  handleRuleProvisioning(rule: UserRule) {
    const rules = this.props.rules.map(x => x);
    rule.index = rule.order;
    rules.push(rule);
    this.props.setRules(rules);
  }

  handleRuleDeletion(ruleId: string) {
    const rules = this.props.rules.map(x => x).filter(x => !!x && x.ruleId !== ruleId);
    rules.forEach((x, i) => x.index = i);
    this.props.setRules(rules);
  }

  handleRuleEdit(rule: UserRule) {
    const rules = this.props.rules.map((x) => x.ruleId === rule.ruleId ? rule : x);
    this.props.setRules(rules);
  }

  updateRuleOrders(rules?: UserRule[]) {
    if (!rules) {
      rules = this.props.rules.map(x => x);
    }

    if (!!rules && rules.some((x, i) => x.order !== i)) {
      this.setState({ showOverlay: true });

      const requestBody: { userRulePatches: {ruleId: string, patch: JSONPatchOperation[]}[] } = {
        userRulePatches: []
      };

      rules.forEach((x, i) => {
        if (i !== x.order) {
          x.order = i;

          requestBody.userRulePatches.push({
            ruleId: x.ruleId,
            patch: [{
              op: 'replace',
              path: '/order',
              value: i
            }]
          });
        } 
      });

      const axios = AxiosSingleton.get();
      const username = localStorage.getItem(USERNAME);
      axios.patch(`users/${username}/rules`, requestBody).then(() => {
        this.props.setRules(rules || []);
        this.setState({showOverlay: false});
      }, (error: AxiosError) => {
        console.log(error);
      });
    }
  }

  updateRerouteURL() {
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    if (username) {
      const requestBody: JSONPatchOperation[] = [{ op: 'replace', path: '/live', value: !(this.props.rerouteURL && this.props.rerouteURL.live) }];

      this.setState({ showOverlay: true });



      axios.patch(`users/${username}/reroute-url`, requestBody).then((response) => {
        this.props.setRerouteURL(response.data);
      }).finally(() => {
        this.setState({ showOverlay: false });
      });
    }
  }

  render() {
    const maxOrder = this.props.rules.length;
    let deadEndIndex = -1;

    const ruleElements = this.props.rules
      .map((x, i) => {
        const contentItem = this.props.contentItems.find(c => c.contentId === x.contentId);
        if (deadEndIndex === -1 && !x.condition && i !== this.MAX_RULE_COUNT - 1 && !!contentItem) {
          deadEndIndex = i;
        }

        return <DraggableWrapper
          key={x.ruleId}
          item={x}
          contentItem={contentItem}
          onDelete={(ruleId: string) => this.handleRuleDeletion(ruleId)}
          onEdit={(rule: Draggable) => this.handleRuleEdit(rule as UserRule)}
          accept={DRAGGABLE_RULE_TYPE}
          moveItem={this.props.moveRule}
          onItemDropped={() => this.updateRuleOrders()}
          buildItemComponent={
            (
              props: DraggableWrapperProps,
              isDragging: boolean,
              moveableRef: RefObject<HTMLDivElement> | undefined,
              dataHandlerID: Identifier | null) => {
              return <Rule
                {...props}
                item={x}
                isDragging={isDragging}
                moveableRef={moveableRef} 
                dataHandlerID={dataHandlerID}
                deadEndRule={i === deadEndIndex}
                followsDeadEndRule={deadEndIndex >= 0 && i > deadEndIndex}
                lastRule={i === this.props.rules.length - 1}/>;
            }
          }/>;
      });

    const showAddRuleButton = 
      <Tooltip
        disableHoverListener={this.props.rules.length < this.MAX_RULE_COUNT}
        title={'You have reached the limit on the amount of rules that you can create.'}
        placement={'left'}>
        <span>
          <Button id="rc-show-add-rule-btn"
            variant='outlined'
            disabled={this.props.rules.length >= this.MAX_RULE_COUNT || !this.props.fetchesComplete }
            onClick={() => { this.setState({ showAddRule: !this.state.showAddRule }); }}>
            { this.showAddRule ? 'Cancel' : 'Add' }
          </Button>
        </span>
      </Tooltip>;

    const username = localStorage.getItem(USERNAME);
    const testURL = (process.env.REACT_APP_MEDIATOR_URL || 'https://rrt.to/') + username;

    const shortLinkFieldAndTestButton = !this.props.rules || !this.props.rules.length
      ? undefined
      :
      <div>
        <OutlinedInput
          id='rc-short-link-field'
          type={'text'}
          size='small'
          disabled={true}
          value={testURL}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="copy"
                edge='end'
                onClick={() => {
                  navigator.clipboard.writeText(testURL);

                  this.setState({showCopiedTooltip: true});

                  setTimeout(() => {
                    this.setState({showCopiedTooltip: false});
                  }, 3000);
                }}
              >
                <Tooltip title='Copied!' open={this.state.showCopiedTooltip} placement='top'>
                  <ContentCopy id='rc-copy-icon' />
                </Tooltip>
              </IconButton>
            </InputAdornment>
          }
        />
        <a id="rc-test-link"
          href={testURL}
          target="_blank"
          rel="noreferrer">
          <span>Test</span>
          <OpenInNewIcon id='rc-open-in-new'/>
        </a>
      </div>;

    return (
      <AppContext.Consumer>
        {
          ctx => (
            !ctx.isLoggedIn
              ? <Navigate to='/login'/>
              :
              <div id="rc-rules-container" className='mobile-drawer-offset'>
                {
                  this.state.showOverlay || !this.props.fetchesComplete || !this.props.rerouteURL
                    ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
                    : <></>
                }
                <h1>Configure your Reroute URL</h1>
                <div id="rc-header-btn-container">
                  <div>
                    <FormControlLabel
                      id="rc-live-switch"
                      control={
                        <Switch
                          color='primary'
                          value={!!this.props.rerouteURL && this.props.rerouteURL.live}
                          checked={!!this.props.rerouteURL && this.props.rerouteURL.live}
                          className={this.props.rerouteURL?.live ? 'live' : 'not-live'}
                          onChange={() => this.updateRerouteURL()} />
                      }
                      label='Live' />
                    { showAddRuleButton }
                  </div>
                  { shortLinkFieldAndTestButton }
                </div>
                {
                  this.showAddRule
                    ?
                    <RulesForm
                      newRuleOrder={maxOrder}
                      contentItems={this.props.contentItems}
                      onRuleProvisioned={(rule) => { this.handleRuleProvisioning(rule); }}/>
                    : <></>
                }
                <ContentContext.Provider value={{ contentItems: this.props.contentItems }}>
                  <div id="rc-rules-list-container">
                    {
                      !this.props.rules || !this.props.rules.length
                        ? this.props.fetchesComplete
                          ? !this.props.contentItems || !this.props.contentItems.length
                            ?
                            <h2 id="rc-helpful-message">
                              It doesn&apos;t look like you have any content sources.<br /><br />
                              Start by <Link to='/content'>adding your content sources</Link>, then come back to create rules based on the sources you&apos;ve added! 
                            </h2>
                            :
                            <h2 id="rc-helpful-message">Get started by adding rules based on your content with the &quot;Add&quot; button above!</h2>
                          : undefined
                        :
                        <DndProvider
                          options={HTML5toTouch}>
                          <DragLayer
                            accept={DRAGGABLE_RULE_TYPE}
                            yOffsetAdjust={-50}
                            buildItemComponent={(ruleId: string) => {
                              const rule = this.props.rules.find(x => x.ruleId === ruleId);
                              return rule
                                ?
                                <Rule
                                  item={rule}
                                  contentItem={this.props.contentItems.find(x => x.contentId === rule.contentId)}
                                  isDragging={false}
                                  deadEndRule={false}
                                  lastRule={true}
                                  followsDeadEndRule={false}
                                  accept={DRAGGABLE_RULE_TYPE}
                                  dataHandlerID={null}/>
                                : <></>;
                            }}/>
                          { ruleElements }
                        </DndProvider>
                    }
                  </div>
                </ContentContext.Provider>
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}
