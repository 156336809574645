import React from 'react';
import Button from '@mui/material/Button';
import { ConfirmModalProps } from './confirm-modal-props';
import './ConfirmModal.scss';

export class ConfirmModal extends React.Component<ConfirmModalProps> {
  constructor(props: ConfirmModalProps) {
    super(props);
  }

  render() {
    return (
      <div id="cmc-confirm-modal-overlay">
        <div id="cmc-confirm-modal-container">
          <div id="cmc-message-container">{this.props.message}</div>
          <div id="cmc-btn-container">
            <Button className='confirm-btn'
              variant='contained'
              onClick={() => { this.props.onClose(true); }}>
              { this.props.confirmText }
            </Button>
            {
              this.props.declineText
                ? 
                <Button className='decline-btn'
                  variant='outlined'
                  onClick={() => { this.props.onClose(false); }}>
                  { this.props.declineText }
                </Button>
                : undefined
            }
          </div>
        </div>
      </div>
    );
  }
}
