import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React from 'react';
import { DisplayNameProps } from './display-name-props';

export class DisplayName extends React.Component<DisplayNameProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div id="dnc-display-name-parent-container" className='field-parent-container'>
        {
          this.props.editDisplayName
            ?
            <div id="dnc-edit-display-name-container" className='profile-input-container'>
              <TextField
                id="dnc-display-name-input"
                label="Display name"
                variant="outlined"
                value={this.props.displayName}
                onChange={(event) => void this.props.displayNameChanged(event.target.value)}
                error={!!this.props.displayNameErrorMessage}
                helperText={this.props.displayNameErrorMessage} />
            </div>
            :
            <div id="dnc-display-name-container" className='static-field-container'>
              <div><strong>Display name:</strong> {this.props.staticDisplayName}</div>
            </div>
        }
        <div id="dnc-display-name-btn-container" className='edit-btn-container'>
          {
            this.props.editDisplayName
              ?
              <Button
                id="dnc-save-display-name-btn"
                className='edit-mode-btn'
                variant='contained'
                onClick={() => void this.props.displayNameSaved()}
                disabled={!!this.props.displayNameErrorMessage || this.props.displayName === this.props.staticDisplayName}>
                Save
              </Button>
              : undefined
          }
          <Button
            id="dnc-edit-display-name-btn"
            className={this.props.editDisplayName ? 'edit-mode-btn' : ''}
            onClick={() => void this.props.editButtonClicked()}>
            { this.props.editDisplayName ? 'Cancel' : 'Edit' }
          </Button>
        </div>
      </div>
    );
  }
}
