import React from 'react';
import { getContentTypeIcon } from '../../../models/user-content/enum/content-type';
import { AUTOCOMPLETE_CONTENT_ICON_STYLES } from '../../app-constants';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ContentAutocompleteProps } from './content-autocomplete-props';
import { UserContent } from '../../../models/user-content/user-content';
import { REQUIRED_FIELD_TEXT } from '../../text-constants';

export class ContentAutocomplete extends React.Component<ContentAutocompleteProps> {
  constructor(props: ContentAutocompleteProps) {
    super(props);

    this.getLabelForContentItem = this.getLabelForContentItem.bind(this);
  }

  getLabelForContentItem(item: UserContent): string {
    if (item.label) {
      return item.label;
    } else if (item.externalId) {
      return item.externalId;
    } else {
      return '';
    }
  }

  render() {
    const contentItemOptions = this.props.contentItems.map((c) => { return { label: this.getLabelForContentItem(c), value: c }; });

    return (
      <Autocomplete
        disablePortal
        id={this.props.id}
        className={this.props.className}
        options={contentItemOptions}
        disabled={this.props.lockInput}
        value={
          this.props.selectedContentItem
            ? { label: this.getLabelForContentItem(this.props.selectedContentItem), value: this.props.selectedContentItem }
            : null
        }
        isOptionEqualToValue={(option, value) => { return option.value.contentId === value.value.contentId; }}
        onChange={(event, value) => void this.props.onContentSelection(value)}
        renderOption={(props, option) => {
          return (
            <li key={option.value.contentId} {...props}>
              { getContentTypeIcon(option.value.contentType, undefined, AUTOCOMPLETE_CONTENT_ICON_STYLES) }&nbsp;&nbsp;
              { option.label }
            </li>
          );
        }}
        renderInput={(params) => (
          <>
            <TextField {...params}
              label="Content*"
              error={!!this.props.errorMessage}
              helperText={this.props.errorMessage || REQUIRED_FIELD_TEXT}
              InputProps={{
                ...params.InputProps,
                startAdornment: 
                  !this.props.selectedContentItem
                    ? undefined
                    :
                    <InputAdornment position='start'>
                      {getContentTypeIcon(this.props.selectedContentItem.contentType, undefined, AUTOCOMPLETE_CONTENT_ICON_STYLES)}
                    </InputAdornment>
              }}/>
          </>
        )}
      />
    );
  }
}
