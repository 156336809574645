import { ContentType } from '../../user-content/enum/content-type';
import { ContentTarget } from './content-target';

export enum TargetCondition {
  LIVE = 'LIVE',
  PUBLISHED_WITHIN = 'PUBLISHED_WITHIN'
}

export const targetConditionToLabel = (e: string, contentType?: ContentType) => {
  const condition = TargetCondition[e as keyof typeof TargetCondition];

  if (condition === TargetCondition.LIVE) {
    return contentType === ContentType.TWITCH_ACCOUNT ? 'Stream is live' : 'Live';
  } else {
    return contentType === ContentType.YOUTUBE_CHANNEL ? 'Published within the last' : 'Posted within the last';
  }
};

export const getTargetConditionFilterForContentTypeAndTarget = (contentType: ContentType, target: ContentTarget) => {
  if (contentType === ContentType.TWITCH_ACCOUNT) {
    return (e: string) => { return e === TargetCondition.LIVE; };
  } else if (contentType === ContentType.YOUTUBE_CHANNEL) {
    if (target === ContentTarget.VIDEO_STREAM) {
      return (e: string) => { return e === TargetCondition.LIVE; };
    } else if (target === ContentTarget.PROFILE) {
      return () => { return false; }; 
    } else {
      return (e: string) => { return e === TargetCondition.PUBLISHED_WITHIN; };
    }
  } else if (contentType === ContentType.PATREON_CAMPAIGN) {
    if (target === ContentTarget.LATEST_POST) {
      return (e: string) => { return e === TargetCondition.PUBLISHED_WITHIN; };
    } else {
      return () => { return false; };
    }
  } else if (contentType === ContentType.TIKTOK_ACCOUNT) {
    if (target === ContentTarget.LATEST_POST) {
      return (e: string) => { return e === TargetCondition.PUBLISHED_WITHIN; };
    } else {
      return () => { return false; };
    }
  } else {
    return () => { return false; }; 
  }
};
