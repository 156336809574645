import { ContentType } from '../../user-content/enum/content-type';

export enum ContentTarget {
  PROFILE = 'PROFILE',
  VIDEO_STREAM = 'VIDEO_STREAM',
  LATEST_VIDEO = 'LATEST_VIDEO',
  LATEST_POST = 'LATEST_POST'
}

export const contentTargetToLabel = (e: string, contentType?: ContentType) => {
  const target = ContentTarget[e as keyof typeof ContentTarget];

  if (target === ContentTarget.PROFILE) {
    if (contentType === ContentType.PATREON_CAMPAIGN) {
      return 'Campaign page';
    } else if (contentType === ContentType.YOUTUBE_CHANNEL || contentType === ContentType.TWITCH_ACCOUNT) {
      return 'Channel';
    } else {
      return 'Profile';
    }
  } else if (target === ContentTarget.LATEST_POST) {
    return 'Latest post';
  } else if (target === ContentTarget.VIDEO_STREAM) {
    return 'Live stream';
  } else {
    return 'Latest video';
  }
};

const youtubeContentTargetFilter = (e: string) => {
  const target = ContentTarget[e as keyof typeof ContentTarget];

  return target === ContentTarget.PROFILE || target === ContentTarget.VIDEO_STREAM || target === ContentTarget.LATEST_VIDEO; 
};

const twitchContentTargetFilter = (e: string) => {
  const target = ContentTarget[e as keyof typeof ContentTarget];

  return target === ContentTarget.PROFILE;
};

const patreonContentTargetFilter = (e: string) => {
  const target = ContentTarget[e as keyof typeof ContentTarget];

  return target === ContentTarget.PROFILE || target === ContentTarget.LATEST_POST;
};

const tiktokContentTargetFilter = (e: string) => {
  const target = ContentTarget[e as keyof typeof ContentTarget];

  return target === ContentTarget.PROFILE || target === ContentTarget.LATEST_POST;
};

export const getContentTargetFilterForContentType = (type: ContentType) => {
  if (type === ContentType.YOUTUBE_CHANNEL) {
    return youtubeContentTargetFilter;
  } else if (type === ContentType.TWITCH_ACCOUNT) {
    return twitchContentTargetFilter;
  } else if (type === ContentType.PATREON_CAMPAIGN) {
    return patreonContentTargetFilter;
  } else if (type === ContentType.TIKTOK_ACCOUNT) {
    return tiktokContentTargetFilter;
  } else {
    return () => false;
  }
};
