import React from 'react';
import ReactDOM from 'react-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Login.scss';
import { LoginState } from './login-state';
import { TOKEN, USERNAME } from '../../common/cookies';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import AppContext from '../../common/context/AppContext';
import { Navigate} from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { ConfirmModal } from '../../common/components/ConfirmModal/ConfirmModal';
import { Link as RouterLink } from 'react-router-dom';
import { ACCOUNT_CLAIM_RESEND_CODE, ACCOUNT_CLAIM_ROUTE, CONTENT_ROUTE, USERS_ROUTE } from '../../common/app-constants';


export class Login extends React.Component<any, LoginState> {
  constructor(props: any) {
    super(props);

    this.state = {
      username: '',
      password: '',
      errorMessage: '',
      lockInput: false,
      showModal: false,
      navigateToHome: false,
      navigateToAccountClaim: false
    };

    this.login = this.login.bind(this);
  }

  login(successCallback: () => void) {
    this.setState({ lockInput: true });
    const ax = axios.create({
      baseURL: process.env.REACT_APP_HOME_API_URL
    });

    const requestBody = {
      username: this.state.username,
      password: this.state.password
    };

    ax.post('login', requestBody).then((response) => {
      this.setState({ lockInput: false });
      if (response && response.data) {
        localStorage.setItem(TOKEN, response.data.token);
        localStorage.setItem(USERNAME, requestBody.username);
        successCallback();
      }
    }, (error: AxiosError<{message: string}>) => {
      if (error.response?.status === 409) {
        this.setState({ errorMessage: 'Please claim account in order to login.', showModal: true });
      } else if (error && error.response && error.response.data) {
        this.setState({ errorMessage: error.response.data.message, lockInput: false });
      } else {
        this.setState({ lockInput: false });
      }
    });
  }


  render() {
    return (
      <AppContext.Consumer>
        {
          ctx => (
            ctx.isLoggedIn
              ? <Navigate to={CONTENT_ROUTE}/>
              :
              <div id="lc-login-container">
                {
                  this.state.navigateToHome
                    ? <Navigate to={'/'}/>
                    : undefined
                }
                {
                  this.state.navigateToAccountClaim
                    ? <Navigate to={`${USERS_ROUTE}/${this.state.username.toLowerCase()}${ACCOUNT_CLAIM_ROUTE}/${ACCOUNT_CLAIM_RESEND_CODE}`}/>
                    : undefined
                }
                {
                  this.state.showModal
                    ? ReactDOM.createPortal(
                      <ConfirmModal
                        message={'Please claim your account by using the link that was emailed to you or request a new one.'}
                        confirmText={'Ok'}
                        declineText={'Request another link'}
                        onClose={(confirmed) => {
                          if (confirmed) {
                            this.setState({ navigateToHome: true });
                          } else {
                            this.setState({ navigateToAccountClaim: true });
                          }
                        }} />,
                      document.getElementById('modal-portal-container') as HTMLElement)
                    : <></>
                }
                <div id="lc-login-input-container">
                  <div id="lc-login-input-flex-container">
                    <TextField
                      id="lc-username-input"
                      className='login-input'
                      autoFocus={true}
                      label="Username"
                      variant="outlined"
                      value={this.state.username}
                      error={!!this.state.errorMessage}
                      disabled={this.state.lockInput}
                      onChange={(event) => void this.setState({username: event.target.value, errorMessage: ''})} />
                    <TextField id="lc-password-input"
                      className='login-input'
                      label="Password"
                      variant="outlined"
                      value={this.state.password}
                      error={!!this.state.errorMessage}
                      disabled={this.state.lockInput}
                      onChange={(event) => void this.setState({password: event.target.value, errorMessage: ''})}
                      onKeyUp={(event) => { if (event && event.code === 'Enter') {
                        this.login(() => { ctx.setIsLoggedIn(true); });
                      } }}
                      helperText={this.state.errorMessage}
                      type={'password'} />
                    <RouterLink id='lc-login-help-link' className='link' to='/login-help'>Need help logging in?</RouterLink>
                  </div>
                </div>
                <div id="lc-button-container">
                  <Button id="lc-login-btn"
                    disabled={!this.state.username || !this.state.password || !!this.state.errorMessage || this.state.lockInput}
                    variant="contained"
                    onClick={() => {this.login(() => { ctx.setIsLoggedIn(true); });}}>
                    { this.state.lockInput ? <LoadingSpinner size={15} /> : 'Login'}
                  </Button>
                </div>
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}