import { useEffect, useState } from 'react';
import AxiosSingleton from '../web/axios-singleton';
import { USERNAME } from '../cookies';
import { AxiosError } from 'axios';
import { UserRerouteURL } from '../../models/user-reroute-url/user-reroute-url';

export const useRerouteURL = () => {
  const state = useState<undefined | UserRerouteURL>(undefined); 
  const axios = AxiosSingleton.get();
  const username = localStorage.getItem(USERNAME);
  const url = `users/${username}/reroute-url`;

  useEffect(
    () => {
      axios.get(url).then((response) => {
        state[1](response.data);
      }, (error: AxiosError) => {
        if (error.response?.status === 404) {
          axios.post(url, { live: false }).then((response) => {
            state[1](response.data);
          });
        }
      });
    },
    []
  );

  return state;
};