import React, { RefObject, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import './RoutePage.scss';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RoutePageState } from './route-page-state';
import AxiosSingleton from '../../common/web/axios-singleton';
import { USERNAME } from '../../common/cookies';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingOverlay } from '../../common/components/LoadingOverlay/LoadingOverlay';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as ContentCopy } from '../../assets/icons/content_copy.svg';
import { ReactComponent as AddCicleIcon } from '../../assets/icons/add_circle.svg';
import { ColorPicker } from './ColorPicker/ColorPicker';
import { RoutePageItemForm } from './common/RoutePageItemForm/RoutePageItemForm';
import AppContext from '../../common/context/AppContext';
import { Navigate } from 'react-router-dom';
import { UserRoutePageItem } from '../../models/user-route-page/user-route-page-item';
import { Link } from 'react-router-dom';
import { RoutePageItem } from './RoutePageItem/RoutePageItem';
import { DRAGGABLE_ROUTE_PAGE_ITEM_TYPE } from '../../common/app-constants';
import ContentContext from '../../common/context/ContentContext';
import { UserContent } from '../../models/user-content/user-content';
import { RoutePageProps } from './route-page-props';
import { UserRoutePage } from '../../models/user-route-page/user-route-page';
import update from 'immutability-helper';
import { DraggableWrapper } from '../../common/components/drag-and-drop/DraggableWrapper/DraggableWrapper';
import { DraggableWrapperProps } from '../../common/components/drag-and-drop/DraggableWrapper/draggable-wrapper-props';
import { Identifier } from 'dnd-core';
import { Draggable } from '../../models/common/drag-and-drop/draggable';
import { JSONPatchOperation } from '../../models/common/web/json-patch-operation';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { DragLayer } from '../../common/components/drag-and-drop/DragLayer/DragLayer';
import Button from '@mui/material/Button';
import { RoutePagePreview } from './RoutePagePreview/RoutePagePreview';
import { UserInfo } from '../../models/user-info';


export const RoutePageWrapper = () => {
  const [routePageItems, setItems] = useState<UserRoutePageItem[]>([]);
  const [contentItems, setContentItems] = useState<UserContent[]>([]);
  const [routePage, setRoutePage] = useState<UserRoutePage>();
  const [displayName, setDisplayname] = useState<string>();
  const [routePageFetchComplete, setRoutePageFetchComplete] = useState<boolean>(false);
  const [contentAndItemsFetchComplete, setContentAndItemsFetchComplete] = useState<boolean>(false);

  const moveItem = useCallback(
    (dragIndex: number | null, hoverIndex: number | null) => {
      if (dragIndex === null || hoverIndex === null) {
        setItems((previousItems: UserRoutePageItem[]) => {
          const newItems = previousItems.map(x => x);
          newItems.sort((a, b) => {
            return a.order - b.order;
          });
          newItems.forEach(x => x.index = x.order);

          return newItems;
        });
      } else {
        setItems((previousItems: UserRoutePageItem[]) => {
          const newItems = update(previousItems, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, previousItems[dragIndex] as UserRoutePageItem],
            ],
          });
    
          newItems.forEach((x, i) => x.index = i);
    
          return newItems;
        });
      }
    },
    []
  );

  if (!routePageFetchComplete || !contentAndItemsFetchComplete) {
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    if (!routePageFetchComplete && username) {
      const routePageEndpoint = `users/${username}/route-page`;

      axios.get(routePageEndpoint).then((response) => {
        setRoutePageFetchComplete(true);
        setRoutePage(response.data);
        return Promise.resolve();
      }).catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 404) {
          
          const requestBody = {
            live: false,
            headerTextColor: 'rgb(0, 0, 0)',
            textColor: 'rgb(0, 0, 0)',
            buttonColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgb(255, 255, 255)',
            buttonAccentColor: 'rgb(0, 0, 0)'
          };

          axios.post(routePageEndpoint, requestBody).then((routePagePostResult) => {
            setRoutePageFetchComplete(true);
            setRoutePage(routePagePostResult.data);
          }, (error) => {
            console.log(error);
            setRoutePageFetchComplete(true);
          });
        }
      });
    }

    if (routePageFetchComplete && !contentAndItemsFetchComplete && username) {
      Promise.allSettled(
        [
          axios.get(`users/${username}/content`),
          axios.get(`users/${username}/route-page-items`),
          axios.get(`users/${username}`)
        ]
      ).then((results: PromiseSettledResult<AxiosResponse<any, any>>[]) => {
        const contentFetchResult = results[0];
  
        if (
          contentFetchResult.status === 'fulfilled'
          && contentFetchResult.value
          && contentFetchResult.value.data
          && contentFetchResult.value.data.embedded
          && contentFetchResult.value.data.embedded.length
        ) {
          setContentItems(contentFetchResult.value.data.embedded);
        }
  
        const routePageItemsFetchResult = results[1];
  
        if (
          routePageItemsFetchResult.status === 'fulfilled'
          && routePageItemsFetchResult.value
          && routePageItemsFetchResult.value.data
          && routePageItemsFetchResult.value.data.embedded
          && routePageItemsFetchResult.value.data.embedded.length
        ) {
          const items: UserRoutePageItem[] = routePageItemsFetchResult.value.data.embedded;
          items.forEach(x => x.index = x.order);
          setItems(items);
        }

        const userGetResult = results[2];
  
        if (
          userGetResult.status === 'fulfilled'
          && userGetResult.value
          && userGetResult.value.data
        ) {
          const user: UserInfo = userGetResult.value.data;
          setDisplayname(user.displayName || user.username);
        }
  
        setContentAndItemsFetchComplete(true);
      });
    }
  }

  return (
    <RoutePage
      routePageItems={routePageItems}
      displayName={displayName}
      moveItem={moveItem}
      setRoutePageItems={setItems}
      setRoutePage={setRoutePage}
      contentItems={contentItems}
      routePage={routePage}
      fetchesComplete={routePageFetchComplete && contentAndItemsFetchComplete} />
  );
};

enum ColorChange {
  HEADER_TEXT,
  BACKGROUND,
  TEXT,
  BUTTON,
  BUTTON_ACCENT
}

class RoutePage extends React.Component<RoutePageProps, RoutePageState> {
  private readonly ROUTE_PAGE_STYLING_KEY = 'route_page_styling'; 

  constructor(props: RoutePageProps) {
    super(props);

    this.state = {
      showLoadingOverlay: false,
      showCopiedTooltip: false,
      showRoutePageItemForm: false,
      showPreview: false
    };

    this.handleLiveStatusChange = this.handleLiveStatusChange.bind(this);
    this.addRoutePageItem = this.addRoutePageItem.bind(this);
    this.handleItemDeletion = this.handleItemDeletion.bind(this);
    this.updateEditedItem = this.updateEditedItem.bind(this);
    this.updateItemOrders = this.updateItemOrders.bind(this);
    this.updateColor = this.updateColor.bind(this);
    this.openPreview = this.openPreview.bind(this);
  }

  handleLiveStatusChange() {
    const routePage = this.props.routePage;

    if (routePage) {
      const username = localStorage.getItem(USERNAME);
      const axios = AxiosSingleton.get();

      if (username) {
        this.setState({ showLoadingOverlay: true });
        const requestBody: JSONPatchOperation[] = [{
          op: 'replace',
          path: '/live',
          value: !routePage.live
        }];

        axios.patch(`users/${username}/route-page`, requestBody).then((response) => {
          this.setState({ showLoadingOverlay: false });
          this.props.setRoutePage(response.data);
        }, (error) => {
          console.log(error);
          this.setState({ showLoadingOverlay: false });
        });
      }
    }
  }

  addRoutePageItem(item: UserRoutePageItem | undefined) {
    if (item) {
      const currentItems = this.props.routePageItems || [];
      item.index = item.order;
      currentItems.push(item);
      this.props.setRoutePageItems(currentItems);
      this.setState({ showRoutePageItemForm: false });
    } else {
      this.setState({ showRoutePageItemForm: false });
    }
  }

  updateEditedItem(item: UserRoutePageItem) {
    item.index = item.order;
    const items = (this.props.routePageItems || [item]).map(x => x.routePageItemID === item.routePageItemID ? item : x);
    this.props.setRoutePageItems(items);
  }

  handleItemDeletion(id: string) {
    const items = this.props.routePageItems?.filter(x => x.routePageItemID !== id).map(x => x);
    this.props.setRoutePageItems(items);
  }

  updateItemOrders(items?: UserRoutePageItem[]) {
    if (!items) {
      items = this.props.routePageItems.map(x => x);
    }

    if (!!items && items.some((x, i) => x.order !== i)) {
      this.setState({ showLoadingOverlay: true });

      const requestBody: { patches: {routePageItemID: string, patch: JSONPatchOperation[]}[] } = {
        patches: []
      };

      items.forEach((x, i) => {
        if (i !== x.order) {
          x.order = i;

          requestBody.patches.push({
            routePageItemID: x.routePageItemID,
            patch: [{
              op: 'replace',
              path: '/order',
              value: i
            }]
          });
        } 
      });

      const axios = AxiosSingleton.get();
      const username = localStorage.getItem(USERNAME);
      axios.patch(`users/${username}/route-page-items`, requestBody).then(() => {
        this.props.setRoutePageItems(items || []);
        this.setState({ showLoadingOverlay: false });
      }, (error: AxiosError) => {
        console.log(error);
        this.setState({ showLoadingOverlay: false });
      });
    }
  }

  updateColor(field: ColorChange, color: string) {

    const requestBody: JSONPatchOperation[] = [];

    if (field === ColorChange.HEADER_TEXT && color !== this.props.routePage?.headerTextColor) {
      requestBody.push(
        {
          op: 'replace',
          path: '/headerTextColor',
          value: color
        }
      );
    } else if (field === ColorChange.BACKGROUND && color !== this.props.routePage?.backgroundColor) {
      requestBody.push(
        {
          op: 'replace',
          path: '/backgroundColor',
          value: color
        }
      );
    } else if (field === ColorChange.TEXT && color !== this.props.routePage?.textColor) {
      requestBody.push(
        {
          op: 'replace',
          path: '/textColor',
          value: color
        }
      );
    } else if (field === ColorChange.BUTTON && color !== this.props.routePage?.buttonColor) {
      requestBody.push(
        {
          op: 'replace',
          path: '/buttonColor',
          value: color
        }
      );
    } else if (field === ColorChange.BUTTON_ACCENT && color !== this.props.routePage?.buttonAccentColor) {
      requestBody.push(
        {
          op: 'replace',
          path: '/buttonAccentColor',
          value: color
        }
      );
    }

    if (requestBody.length) {
      this.setState({ showLoadingOverlay: true });
      const username = localStorage.getItem(USERNAME);
      const axios = AxiosSingleton.get();

      if (username) {
        axios.patch(`users/${username}/route-page`, requestBody).then((response) => {
          this.setState({ showLoadingOverlay: false });
          this.props.setRoutePage(response.data);
        }, (error) => {
          console.log(error);
          this.setState({ showLoadingOverlay: false });
        });
      }
    }
  }

  async openPreview() {
    const storageItem = sessionStorage.getItem(this.ROUTE_PAGE_STYLING_KEY);

    if (!storageItem) {
      const axios = AxiosSingleton.get();
      const username = localStorage.getItem(USERNAME);

      if (username) {
        this.setState({ showLoadingOverlay: true });
        const response = await axios.get(`users/${username}/route-page/styling`);

        if (response.status === 200) {
          sessionStorage.setItem(this.ROUTE_PAGE_STYLING_KEY, JSON.stringify(response.data));
        }

        this.setState({ showLoadingOverlay: false });
      }
    }

    this.setState({ showPreview: true });
  }

  render() {
    const username = localStorage.getItem(USERNAME);
    const routePageURL = (process.env.REACT_APP_ROUTE_PAGE_URL || 'https://rrt.page/') + username;

    const routePageItems = !this.props.routePageItems
      ? []
      :
      this.props.routePageItems.map(x => {
        const contentItem = this.props.contentItems?.find(c => c.contentId === x.contentID);
        return <DraggableWrapper
          key={x.routePageItemID + x.contentID}
          item={x}
          contentItem={contentItem}
          onDelete={(itemID: string) => this.handleItemDeletion(itemID)}
          onEdit={(item: Draggable) => this.updateEditedItem(item as UserRoutePageItem)}
          accept={DRAGGABLE_ROUTE_PAGE_ITEM_TYPE}
          moveItem={this.props.moveItem}
          onItemDropped={() => this.updateItemOrders()}
          buildItemComponent={(
            props: DraggableWrapperProps,
            isDragging: boolean,
            moveableRef: RefObject<HTMLDivElement> | undefined,
            dataHandlerID: Identifier | null
          ) => {
            return (
              <RoutePageItem
                {...props}
                item={x}
                isDragging={isDragging}
                moveableRef={moveableRef} 
                dataHandlerID={dataHandlerID}
                contentItem={contentItem}/>
            );
          }}/>;
      });


    if (this.state.showRoutePageItemForm) {
      routePageItems.push(
        <div
          id="rpifc-add-item-wrapper"
          key='rpifc-add-item-wrapper'>
          <RoutePageItemForm
            contentItems={this.props.fetchesComplete && this.props.contentItems ? this.props.contentItems : []}
            order={
              this.props.routePageItems && this.props.routePageItems.length
                ? this.props.routePageItems[this.props.routePageItems.length - 1].order + 1
                : 0
            }
            onRoutePageItemProvisioned={(item) => { this.addRoutePageItem(item); }}/>
        </div>
      );
    }

    return (
      <AppContext.Consumer>
        {
          ctx => (
            !ctx.isLoggedIn
              ? <Navigate to='/login'/>
              :
              <div id="rpc-route-page-container" className='mobile-drawer-offset'>
                {
                  this.state.showLoadingOverlay || !this.props.fetchesComplete
                    ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
                    : undefined
                }
                {
                  this.state.showPreview
                    ?
                    ReactDOM.createPortal(
                      <RoutePagePreview
                        url={routePageURL}
                        displayName={this.props.displayName}
                        routePageStylingKey={this.ROUTE_PAGE_STYLING_KEY}
                        routePage={this.props.routePage}
                        items={this.props.routePageItems.map(x =>  { 
                          return {
                            link: x,
                            content: this.props.contentItems.find(c => c.contentId === x.contentID)
                          };
                        })}
                        onPreviewClose={() => this.setState({ showPreview: false })} />,
                      document.getElementById('route-page-preview-portal-container') as HTMLElement
                    )
                    : undefined
                }
                <h1>This is your Route Page</h1>
                <div className='route-page-row'>
                  <div>
                    <FormControlLabel
                      id="rpc-live-switch"
                      control={
                        <Switch
                          color='primary'
                          value={!!this.props.routePage && this.props.routePage.live}
                          checked={!!this.props.routePage && this.props.routePage.live}
                          className={this.props.routePage?.live ? 'live' : 'not-live'}
                          onChange={() => this.handleLiveStatusChange()} />
                      }
                      label='Live' />
                    <Button
                      id="rpc-preview-btn"
                      color='primary'
                      variant='contained'
                      onClick={() => this.openPreview()}>
                      Preview
                    </Button>
                  </div>
                  <div>
                    <OutlinedInput
                      id="rpc-short-link-field"
                      type={'text'}
                      size='small'
                      disabled={true}
                      value={routePageURL}
                      fullWidth={true}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            id="rpc-copy-link-btn"
                            aria-label="copy"
                            edge='end'
                            onClick={() => {
                              navigator.clipboard.writeText(routePageURL);

                              this.setState({showCopiedTooltip: true});

                              setTimeout(() => {
                                this.setState({showCopiedTooltip: false});
                              }, 3000);
                            }}
                          >
                            <Tooltip title='Copied!' open={this.state.showCopiedTooltip} placement='top'>
                              <ContentCopy id="rpc-copy-icon" />
                            </Tooltip>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>
                {
                  !this.props.fetchesComplete
                    ? undefined
                    :
                    <>
                      <div className='route-page-customization-row'>
                        <ColorPicker
                          id="rpc-header-text-color-picker"
                          label='Header text color'
                          selectedColor={this.props.fetchesComplete && this.props.routePage ? this.props.routePage.headerTextColor : 'black'}
                          onChangeComplete={(color: string) => void this.updateColor(ColorChange.HEADER_TEXT, color)}/>
                        <ColorPicker
                          id="rpc-background-color-picker"
                          label='Background color'
                          selectedColor={this.props.fetchesComplete && this.props.routePage ? this.props.routePage.backgroundColor : 'white'}
                          onChangeComplete={(color: string) => void this.updateColor(ColorChange.BACKGROUND, color)}/>
                      </div>
                      <div className='route-page-customization-row'>
                        <ColorPicker
                          id="rpc-button-color-picker"
                          label='Button color'
                          selectedColor={this.props.fetchesComplete && this.props.routePage ? this.props.routePage.buttonColor : 'white'}
                          onChangeComplete={(color: string) => void this.updateColor(ColorChange.BUTTON, color)}/>
                        <ColorPicker
                          id="rpc-text-color-picker"
                          label='Button text color'
                          selectedColor={this.props.fetchesComplete && this.props.routePage ? this.props.routePage.textColor : 'black'}
                          onChangeComplete={(color: string) => void this.updateColor(ColorChange.TEXT, color)}/>
                      </div>
                      <div className='route-page-customization-row'>
                        <ColorPicker
                          id="rpc-button-accent-color-picker"
                          label='Button accent color'
                          selectedColor={this.props.fetchesComplete && this.props.routePage ? this.props.routePage.buttonAccentColor : 'black'}
                          onChangeComplete={(color: string) => void this.updateColor(ColorChange.BUTTON_ACCENT, color)}/>
                      </div>
                    </>
                }
                <div id="rpc-items-container">
                  <ContentContext.Provider value={{ contentItems: this.props.contentItems }}>
                    <h2>Links</h2>
                    {
                      this.props.fetchesComplete && (!this.props.contentItems || !this.props.contentItems.length)
                        ?
                        <h3 id="rpc-helpful-message">
                          It doesn&apos;t look like you have any content sources.<br /><br />
                          Start by <Link to='/content'>adding your content sources</Link>, then come back to add links based on the sources you&apos;ve added! 
                        </h3>
                        :
                        <>
                          {
                            routePageItems.length
                              ?
                              <DndProvider options={HTML5toTouch}>
                                <DragLayer
                                  accept={DRAGGABLE_ROUTE_PAGE_ITEM_TYPE}
                                  yOffsetAdjust={-10}
                                  buildItemComponent={(id: string) => {
                                    const routePageItem = this.props.routePageItems.find(x => x.routePageItemID === id);
                                    return routePageItem
                                      ?
                                      <RoutePageItem
                                        item={routePageItem}
                                        contentItem={this.props.contentItems.find(x => x.contentId === routePageItem.contentID)}
                                        isDragging={false}
                                        accept={DRAGGABLE_ROUTE_PAGE_ITEM_TYPE}
                                        dataHandlerID={null}/>
                                      : <></>;
                                  }}/>
                                { routePageItems }
                              </DndProvider>
                              :
                              <h3 id="rpc-helpful-message">
                                Add links to your Route Page with the button below.
                              </h3>
                          }
                          {
                            this.state.showRoutePageItemForm || !this.props.fetchesComplete
                              ? undefined
                              :
                              <IconButton
                                id="rpc-add-item-button"
                                aria-label='add item'
                                size='large'
                                onClick={() => void this.setState({ showRoutePageItemForm: true })}>
                                <Tooltip title='Add a link'>
                                  <AddCicleIcon />
                                </Tooltip>
                              </IconButton>
                          }
                        </>
                    }
                  </ContentContext.Provider>
                </div>
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}