import React from 'react';
import ReactDOM from 'react-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Register.scss';
import { RegisterState } from './register-state';
import validateInput, { maxLength, minLength, regEx } from '../../common/validation/input-validators';
import { EMAIL_REGULAR_EXPRESSION, PASSWORD_REGULAR_EXPRESSION, USERNAME_REGULAR_EXPRESSION } from '../../common/validation/regular-expressions';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import AppContext from '../../common/context/AppContext';
import { Navigate } from 'react-router-dom';
import { PASSWORD_ERROR_MESSAGE } from '../../common/validation/constants';
import { ConfirmModal } from '../../common/components/ConfirmModal/ConfirmModal';
import { ACCOUNT_CLAIM_SENT_MESSAGE } from '../../common/text-constants';
import { CONTENT_ROUTE } from '../../common/app-constants';
import axios from 'axios';

export class Register extends React.Component<any, RegisterState> {

  private VALIDATORS: any = {
    username: [
      minLength(3),
      maxLength(16),
      regEx(USERNAME_REGULAR_EXPRESSION, 'Please enter a username that starts with a letter or number and is limited to special characters - and _ .')
    ],
    password: [
      regEx(PASSWORD_REGULAR_EXPRESSION, PASSWORD_ERROR_MESSAGE),
      maxLength(16)
    ],
    email: [ regEx(EMAIL_REGULAR_EXPRESSION, 'Please provide a valid email address.') ]
  };

  constructor(props: any) {
    super(props);

    this.state = {
      username: '',
      usernameErrorMessage: '',
      password: '',
      passwordErrorMessage: '',
      confirmPassword: '',
      confirmPasswordErrorMessage: '',
      confirmPasswordTouched: false,
      email: '',
      emailErrorMessage: '',
      confirmEmail: '',
      confirmEmailErrorMessage: '',
      confirmEmailTouched: false,
      lockInput: false,
      showModal: false,
      navigateToHome: false
    };

    this.postUser = this.postUser.bind(this);
  }

  private get confirmPasswordInvalid(): boolean {
    return this.state.confirmPasswordTouched && this.state.password !== this.state.confirmPassword;
  }

  private get confirmEmailInvalid(): boolean {
    return this.state.confirmEmailTouched && this.state.email !== this.state.confirmEmail;
  }

  public get formInvalid(): boolean {
    return this.state.username === '' || !!this.state.usernameErrorMessage
      || this.state.email === '' || !!this.state.emailErrorMessage
      || this.state.confirmEmail === '' || this.confirmEmailInvalid
      || this.state.password === '' || !!this.state.passwordErrorMessage
      || this.state.confirmPassword === '' || this.confirmPasswordInvalid;
  }

  private handleInput(newState: any) {
    const property = Object.keys(newState)[0];
    newState[property + 'ErrorMessage'] = validateInput(newState[property], this.VALIDATORS[property]);

    this.setState(newState as RegisterState);
  }

  private postUser() {
    this.setState({ lockInput: true });
    const ax = axios.create({
      baseURL: process.env.REACT_APP_HOME_API_URL
    });

    const requestBody = {
      username: this.state.username,
      displayName: this.state.username,
      password: this.state.password,
      email: this.state.email
    };

    ax.post('/users', requestBody).then(() => {
      this.setState({ showModal: true });
    }, (error) => {
      if (error && error.response && error.response.status === 409) {
        this.setState({ usernameErrorMessage: 'Sorry, that username has already been taken.', lockInput: false });
      } else {
        this.setState({ lockInput: false });
      }
    });
  }

  render() {
    return (
      <AppContext.Consumer>
        {
          ctx => (
            ctx.isLoggedIn
              ? <Navigate to={CONTENT_ROUTE}/>
              :
              <div id="rc-register-container">
                <TextField
                  id="rc-username-input"
                  className='register-input'
                  autoFocus={true}
                  label="Username"
                  variant="outlined"
                  value={this.state.username}
                  onChange={(event) => void this.handleInput({username: event.target.value})}
                  error={!!this.state.usernameErrorMessage}
                  disabled={this.state.lockInput}
                  helperText={this.state.usernameErrorMessage} />
                <TextField id="rc-password-input"
                  className='register-input'
                  label="Password"
                  variant="outlined"
                  value={this.state.password}
                  onChange={(event) => void this.handleInput({password: event.target.value})}
                  error={!!this.state.passwordErrorMessage}
                  type={'password'}
                  disabled={this.state.lockInput}
                  helperText={this.state.passwordErrorMessage}/>
                <TextField id="rc-confirm-password-input"
                  className='register-input'
                  label="Confirm password"
                  variant="outlined"
                  value={this.state.confirmPassword}
                  onChange={(event) => void this.setState({confirmPassword: event.target.value})}
                  error={this.confirmPasswordInvalid}
                  type={'password'}
                  disabled={this.state.lockInput}
                  helperText={this.confirmPasswordInvalid ? 'Passwords do not match.' : ''}
                  onFocus={
                    () => {
                      if (!this.state.confirmPasswordTouched) {
                        this.setState({ confirmPasswordTouched: true });
                      }
                    }
                  } />
                <TextField id="rc-email-input"
                  className='register-input'
                  label="Email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={(event) => void this.handleInput({email: event.target.value})}
                  error={!!this.state.emailErrorMessage}
                  disabled={this.state.lockInput}
                  helperText={this.state.emailErrorMessage} /> 
                <TextField id="rc-confirm-email-input"
                  className='register-input'
                  label="Confirm email"
                  variant="outlined"
                  value={this.state.confirmEmail}
                  onChange={(event) => void this.setState({confirmEmail: event.target.value})}
                  error={this.confirmEmailInvalid}
                  disabled={this.state.lockInput}
                  helperText={this.confirmEmailInvalid ? 'Emails do not match.' : ''}
                  onFocus={
                    () => {
                      if (!this.state.confirmEmailTouched) {
                        this.setState({ confirmEmailTouched: true });
                      }
                    }
                  } />
                <div id="rc-button-container">
                  <Button id="rc-register-btn"
                    disabled={this.state.lockInput || this.formInvalid}
                    variant="contained"
                    onClick={this.postUser}>
                    { this.state.lockInput ? <LoadingSpinner size={15} /> : 'Register' }
                  </Button>
                </div>
                {
                  this.state.navigateToHome
                    ? <Navigate to='/'/>
                    : undefined
                }
                {
                  this.state.showModal
                    ? ReactDOM.createPortal(
                      <ConfirmModal
                        message={ACCOUNT_CLAIM_SENT_MESSAGE}
                        confirmText={'Ok'}
                        onClose={() => {
                          this.setState({ navigateToHome: true });
                        }} />,
                      document.getElementById('modal-portal-container') as HTMLElement)
                    : undefined
                }
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}