export const PROD_ENV = 'prod';

// URLs

export const CDN_URL =  process.env.REACT_APP_CDN_URL || 'https://cdn.reroute.live/';

// element IDs

export const ABOUT_SECTION_ID = 'about';

export const PRODUCTS_SECTION_ID = 'products';

export const GET_STARTED_SECTION_ID = 'get-started';

// routes

export const REGISTER_ROUTE = '/register';

export const CONTENT_ROUTE = '/content';

export const RULES_ROUTE = '/rules';

export const ROUTE_PAGE_ROUTE = '/route-page';

export const PROFILE_ROUTE = '/profile';

export const USERS_ROUTE = '/users';

export const ACCOUNT_CLAIM_ROUTE = '/account-claim';

// styling

export const INSTAGRAM_GRADIENT_CLASS = 'instagram-gradient-background';

export const INSTAGRAM_ICON_SIZE = 'instagram-icon-size';

export const INSTAGRAM_BACKGROUND_BORDER = 'instagram-background-border';

export const AUTOCOMPLETE_CONTENT_ICON_STYLES = { width: 20, height: 20, marginTop: '2px' };

// other

export const MOBILE_MAX_WIDTH = 1000;

export const ACCOUNT_CLAIM_RESEND_CODE = 'RESEND';

export const DRAGGABLE_RULE_TYPE = 'RULE';

export const DRAGGABLE_ROUTE_PAGE_ITEM_TYPE = 'ROUTE_PAGE_ITEM';
