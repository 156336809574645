import { createContext } from 'react';
import { UserContent } from '../../models/user-content/user-content';

export interface ContentContextObject {
  contentItems?: UserContent[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ContentContext = createContext<ContentContextObject>({});

export default ContentContext;
