import { ReactComponent as YouTubeIcon } from '../../../assets/icons/youtube_white.svg';
import { ReactComponent as TwitchIcon } from '../../../assets/icons/twitch_white.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg';
import { ReactComponent as PatreonIcon } from '../../../assets/icons/patreon_black.svg';
import { ReactComponent as TikTokIcon } from '../../../assets/icons/tiktok_color_black.svg';
import { CSSProperties, createElement } from 'react';
import { UserContent } from '../user-content';

const TWITCH_SVG_COLOR_CLASS = 'twitch-svg-color';

const YOUTUBE_SVG_COLOR_CLASS = 'youtube-svg-color';

export enum ContentType {
  YOUTUBE_CHANNEL = 'YOUTUBE_CHANNEL',
  TWITCH_ACCOUNT = 'TWITCH_ACCOUNT',
  PATREON_CAMPAIGN = 'PATREON_CAMPAIGN',
  TIKTOK_ACCOUNT = 'TIKTOK_ACCOUNT',
  URL = 'URL'
}

export const contentTypeToLabel = (e: string) => {
  switch(e) {
  case ContentType.YOUTUBE_CHANNEL.toString():
    return 'YouTube Channel';
  case ContentType.TWITCH_ACCOUNT.toString():
    return 'Twitch Account';
  case ContentType.PATREON_CAMPAIGN.toString():
    return 'Patreon Campaign';
  case ContentType.TIKTOK_ACCOUNT.toString():
    return 'TikTok Account';
  default:
    return 'URL';
  }
};

export const getContentTypeIcon = (type: string | undefined, iconClassName?: string, iconStyles?: CSSProperties) =>  {
  if (type === ContentType.TWITCH_ACCOUNT) {
    return createElement(TwitchIcon, { className: [TWITCH_SVG_COLOR_CLASS, iconClassName].join(' '), style: iconStyles });
  } else if (type === ContentType.YOUTUBE_CHANNEL) {
    return createElement(YouTubeIcon, { className: [YOUTUBE_SVG_COLOR_CLASS, iconClassName].join(' '), style: iconStyles });
  } else if (type === ContentType.PATREON_CAMPAIGN) {
    return createElement(PatreonIcon, { className: iconClassName, style: iconStyles });
  } else if (type === ContentType.TIKTOK_ACCOUNT) {
    return createElement(TikTokIcon, { className: iconClassName, style: iconStyles });
  } else {
    return createElement(LinkIcon, { className: iconClassName, style: iconStyles });
  }
};

export const getContentItemLabel = (item: UserContent) => {
  if (item.label) {
    return item.label;
  } else if (item.externalId) {
    return item.externalId;
  } else {
    return item.url;
  }
};
