import React from 'react';
import './CallToAction.scss';
import { CDN_URL, GET_STARTED_SECTION_ID } from '../../../common/app-constants';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { CallToActionProps } from './call-to-action-props';

export class CallToAction extends React.Component<CallToActionProps> {
  constructor(props: CallToActionProps) {
    super(props);
  }

  render() {
    return (
      <div id={GET_STARTED_SECTION_ID} ref={this.props.callToActionRef}>
        <div id="ctac-inner-container">
          <img id="ctac-background-image" src={( CDN_URL) + 'img/reroute-logo.png'} alt="Reroute logo"/>
          <h1>Get Started For Free</h1>
          <p>
            Reroute is currently in a closed &quot;alpha&quot; stage of its development. Please send an email to
            request an invite and/or demo to start your account creation process.
          </p>
          <a href="mailto:info@reroute.live">
            <MailIcon />
            <span>info@reroute.live</span>
          </a>
        </div>
      </div>
    );
  }
}