export enum AccountType {
  PATREON = 'PATREON',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK'
}

export const accountTypeToLabel = (accountType: AccountType) => {
  if (accountType === AccountType.PATREON) {
    return 'Patreon';
  } else if (accountType === AccountType.TIKTOK) {
    return 'TikTok';
  } else if (accountType === AccountType.INSTAGRAM) {
    return 'Instagram';
  }
};
