import React from 'react';
import ReactDOM from 'react-dom';
import { LoginHelpActionProps } from './login-help-action-props';
import { LoginHelpType } from '../login-help-type';
import { LoginHelpActionState } from './login-help-action-state';
import TextField from '@mui/material/TextField';
import validateInput, { maxLength, minLength, regEx } from '../../../common/validation/input-validators';
import { EMAIL_REGULAR_EXPRESSION, USERNAME_REGULAR_EXPRESSION } from '../../../common/validation/regular-expressions';
import { LoadingSpinner } from '../../../common/components/LoadingSpinner/LoadingSpinner';
import Button from '@mui/material/Button';
import './LoginHelpAction.scss';
import AxiosSingleton from '../../../common/web/axios-singleton';
import { AxiosError } from 'axios';
import { ConfirmModal } from '../../../common/components/ConfirmModal/ConfirmModal';
import { Navigate } from 'react-router-dom';

export class LoginHelpAction extends React.Component<LoginHelpActionProps, LoginHelpActionState> {
  private USERNAME_VALIDATORS = [
    minLength(4),
    maxLength(16),
    regEx(USERNAME_REGULAR_EXPRESSION, 'Please enter a username that starts with a letter or number and is limited to special characters - and _ .')
  ];

  private EMAIL_VALIDATORS = [
    regEx(EMAIL_REGULAR_EXPRESSION, 'Please provide a valid email address.')
  ];

  constructor(props: LoginHelpActionProps) {
    super(props);

    if (props.requestType === LoginHelpType.FORGOT_USERNAME) {
      this.state = {
        lockInput: false,
        instruction: 'You can request your username to be sent to the email that you provide if the account is tied to it.',
        inputLabel: 'Email',
        buttonText: 'Find username',
        showModal: false,
        navigateToLogin: false
      };
    } else {
      this.state = {
        lockInput: false,
        instruction: 'You can request a password reset link to be sent to the email of the account that you enter below.',
        inputLabel: 'Username',
        buttonText: 'Send reset password link',
        showModal: false,
        navigateToLogin: false
      };
    }

    this.handleInput = this.handleInput.bind(this);
    this.postLoginHelp = this.postLoginHelp.bind(this);
  }

  handleInput(value: string) {
    this.setState({
      input: value,
      inputErrorMessage: this.props.requestType === LoginHelpType.FORGOT_USERNAME
        ? validateInput(value, this.EMAIL_VALIDATORS)
        : validateInput(value, this.USERNAME_VALIDATORS)
    });
  }

  postLoginHelp() {
    this.setState({ lockInput: true });
    const axios = AxiosSingleton.get();

    const request = this.props.requestType === LoginHelpType.FORGOT_USERNAME
      ? { email: this.state.input }
      : { username: this.state.input };

    axios.post('login-help', request).then(() => {
      this.setState({
        lockInput: false,
        showModal: true,
        modalText: this.props.requestType === LoginHelpType.FORGOT_USERNAME
          ? 'If the email you provided belongs to an account, you should see an email within the next few minutes with your username. Please be sure to check your spam folder.'
          : 'A password reset link was sent to the email address of the account you entered. Please be sure to check your spam folder.'
      });
    }, (error: AxiosError) => {
      const newState = {
        lockInput: false,
        inputErrorMessage: error.response?.status === 404
          ? 'No account found with that username.'
          : 'Sorry, an error occurred. Please try again later.'
      };

      this.setState(newState);
    });
  }

  render() {
    return (
      this.state.navigateToLogin
        ? <Navigate to={'/login'} />
        :
        <div id="lhac-login-help-action-container">
          {
            this.state.showModal
              ? ReactDOM.createPortal(
                <ConfirmModal
                  message={this.state.modalText || 'An error occurred.'}
                  confirmText={'Ok'}
                  onClose={() => void this.setState({ navigateToLogin: true })} />,
                document.getElementById('modal-portal-container') as HTMLElement)
              : undefined
          }
          <h3 id="lhac-instruction-text">{this.state.instruction}</h3>
          <TextField
            id="lhac-input"
            className='input'
            autoFocus={true}
            label={this.state.inputLabel}
            variant='outlined'
            value={this.state.input || ''}
            error={!!this.state.inputErrorMessage}
            helperText={this.state.inputErrorMessage || ''}
            disabled={this.state.lockInput}
            onChange={(event) => void this.handleInput(event.target.value)} />
          <Button id="lhac-request-btn"
            disabled={!this.state.input || !!this.state.inputErrorMessage || this.state.lockInput}
            variant='contained'
            onClick={() => void this.postLoginHelp()}>
            { this.state.lockInput ? <LoadingSpinner size={15} /> : this.state.buttonText}
          </Button>
          <Button id="lhac-go-back-btn"
            variant='outlined'
            onClick={() => void this.props.onGoBackClicked()}>
            Go back
          </Button>
        </div>
    );
  }
}