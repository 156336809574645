import React from 'react';
import './About.scss';
import { ABOUT_SECTION_ID, CDN_URL } from '../../../common/app-constants';
import { AboutProps } from './about-props';

export class About extends React.Component<AboutProps> {
  constructor(props: AboutProps) {
    super(props);
  }

  render() {
    return (
      <div id={ABOUT_SECTION_ID} ref={this.props.aboutSectionRef}>
        <div id="abc-left-container">
          <div id="abc-left-text-container">
            <h1><span>Route</span> to your most <span>relevant content.</span></h1>
            <p>
              Explore link in bio tools that efficiently distribute your content and build your community.
            </p>
          </div>
        </div>
        <div id="abc-right-container">
          <img id="abc-globe" src={`${( CDN_URL)}img/globe.png`} alt="Globe connectivity graphic art" />
        </div>
      </div>
    );
  }
}
