import React from 'react';
import './RoutePageItemForm.scss';
import { RoutePageItemFormProps } from './route-page-item-form-props';
import { RoutePageItemFormState } from './route-page-item-form-state';
import { ContentAutocomplete } from '../../../../common/components/ContentAutocomplete/ContentAutocomplete';
import { UserContent } from '../../../../models/user-content/user-content';
import TextField from '@mui/material/TextField';
import validateInput, { maxLength, regEx } from '../../../../common/validation/input-validators';
import { LoadingSpinner } from '../../../../common/components/LoadingSpinner/LoadingSpinner';
import Button from '@mui/material/Button';
import { USERNAME } from '../../../../common/cookies';
import AxiosSingleton from '../../../../common/web/axios-singleton';
import { AxiosError } from 'axios';
import { JSONPatchOperation } from '../../../../models/common/web/json-patch-operation';

export class RoutePageItemForm extends React.Component<RoutePageItemFormProps, RoutePageItemFormState> {
  constructor(props: any) {
    super(props);

    this.state = {
      lockInput: false,
      selectedContentItem: this.props.contentItems.find(x => x.contentId === this.props.routePageItem?.contentID),
      labelInput: this.props.routePageItem?.label
    };

    this.setContent = this.setContent.bind(this);
    this.postRoutePageItem = this.postRoutePageItem.bind(this);
    this.patchRoutePageItem = this.patchRoutePageItem.bind(this);
  }

  get formInvalid(): boolean {
    return !this.state.selectedContentItem
      || !!this.state.contentItemErrorMessage
      || !!this.state.labelErrorMessage;
  }

  get editFormValuesChanged(): boolean {
    return this.state.selectedContentItem?.contentId !== this.props.routePageItem?.contentID
      || (this.state.labelInput || '') !== (this.props.routePageItem?.label || '');
  }

  setContent(selection: { label: string, value: UserContent } | null) {
    if (selection) {
      this.setState({ selectedContentItem: selection.value, contentItemErrorMessage: '' });
    } else {
      this.setState({ selectedContentItem: undefined, contentItemErrorMessage: 'Please select a content source.' });
    }
  }

  handleInput(value: string) {
    const errorMessage = validateInput(value, [maxLength(32), regEx(/(^$|\w)/, 'Please provide a valid label.')]);
    this.setState({ labelInput: value, labelErrorMessage: errorMessage });
  }

  postRoutePageItem() {
    const username = localStorage.getItem(USERNAME);

    if (username) {
      this.setState({ lockInput: true });
      const axios = AxiosSingleton.get();
      const requestBody: any = {
        contentID: this.state.selectedContentItem?.contentId,
        order: this.props.order
      };

      if (this.state.labelInput) {
        requestBody.label = this.state.labelInput;
      }

      axios.post(`users/${username}/route-page-items`, requestBody).then((response) => {
        if (response.data && response.status === 201) {
          this.props.onRoutePageItemProvisioned(response.data);
        }
      }, (error: AxiosError) => {
        console.log(error);
      });
    }
  }

  patchRoutePageItem() {
    const username = localStorage.getItem(USERNAME);
    const prev = this.props.routePageItem;

    if (username && prev) {
      this.setState({ lockInput: true });
      const requestBody: JSONPatchOperation[] = [];

      const axios = AxiosSingleton.get();

      if (prev.contentID !== this.state.selectedContentItem?.contentId) {
        requestBody.push({ op: 'replace', path: '/contentID', value: this.state.selectedContentItem?.contentId });
      }

      if ((prev.label || '') !== (this.state.labelInput || '')) {
        requestBody.push({ op: 'replace', path: '/label', value: this.state.labelInput });
      }

      axios.patch(`users/${username}/route-page-items/${this.props.routePageItem?.routePageItemID}`, requestBody).then((response) => {
        this.setState({ lockInput: false });

        if (response.status === 200 && response.data) {
          this.props.onRoutePageItemProvisioned(response.data);
        }
      }, (error) => {
        console.log(error);
        this.setState({ lockInput: false });
      });
      
    }
  }

  render() {
    return (
      <div className='rpifc-route-page-item-form'>
        <ContentAutocomplete
          id={this.props.routePageItem ? 'rpifc-edit-item-autocomplete' : 'rpifc-add-item-autocomplete'}
          className='route-page-item-form-input-field'
          selectedContentItem={this.state.selectedContentItem}
          errorMessage={this.state.contentItemErrorMessage}
          contentItems={this.props.contentItems}
          lockInput={this.state.lockInput}
          onContentSelection={(selection) => this.setContent(selection)}/>
        <TextField
          id="rpifc-label-input"
          label='Label'
          variant='outlined'
          fullWidth={true}
          className='route-page-item-form-input-field'
          value={this.state.labelInput || ''}
          error={!!this.state.labelErrorMessage}
          disabled={this.state.lockInput}
          onChange={(event) => void this.handleInput(event.target.value)}
          helperText={this.state.labelErrorMessage || ''} />
        <div id="rpifc-btn-container">
          <Button
            className='save-link-btn'
            disabled={this.state.lockInput || this.formInvalid || (this.props.routePageItem && !this.editFormValuesChanged)}
            variant='contained'
            onClick={() => {
              if (this.props.routePageItem) {
                this.patchRoutePageItem();
              } else {
                this.postRoutePageItem();
              }
            }}>
            { this.state.lockInput ? <LoadingSpinner size={15} /> : this.props.routePageItem ? 'Save link' : 'Add link'}
          </Button>
          <Button
            className='cancel-btn'
            disabled={this.state.lockInput}
            variant='outlined'
            onClick={() => {
              this.props.onRoutePageItemProvisioned();
            }}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}