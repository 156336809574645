import { AxiosError } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Navigate, useParams } from 'react-router-dom';
import { ConfirmModal } from '../../common/components/ConfirmModal/ConfirmModal';
import { LoadingOverlay } from '../../common/components/LoadingOverlay/LoadingOverlay';
import AxiosSingleton from '../../common/web/axios-singleton';
import { AccountClaimProps } from './account-claim-props';
import { AccountClaimState } from './account-claim-state';
import './AccountClaim.scss';
import AppContext from '../../common/context/AppContext';
import { ACCOUNT_CLAIM_SENT_MESSAGE } from '../../common/text-constants';
import { ACCOUNT_CLAIM_RESEND_CODE, CONTENT_ROUTE } from '../../common/app-constants';

export const AccountClaimWrapper = () => {
  const params = useParams();
  
  return (<AccountClaim username={params.username || ''} claimId={params.claimId || ''} />);
};

class AccountClaim extends React.Component<AccountClaimProps, AccountClaimState> {
  private SUCCESSFUL_ACCOUNT_CLAIM_MESSAGE = 'Account claimed successfully!';
  private ACCOUNT_ALREADY_CLAIMED_MESSAGE = 'Account already claimed.';

  constructor(props: any) {
    super(props);

    this.state = {
      showLoadingOverlay: this.props.claimId !== ACCOUNT_CLAIM_RESEND_CODE,
      showModal: false,
      navigateToLogin: false,
      newAccountClaimAttempted: false
    };

    this.postAccountClaim = this.postAccountClaim.bind(this);
  }

  componentDidMount(): void {
    if (this.props.username && this.props.claimId) {
      const axios = AxiosSingleton.get();

      if (this.props.claimId === ACCOUNT_CLAIM_RESEND_CODE) {
        this.postAccountClaim();
      } else {
        const request = {
          username: this.props.username,
          claimId: this.props.claimId
        };
  
        axios.put('account-claim', request).then(() => {
          this.setState({
            showLoadingOverlay: false,
            showModal: true,
            modalText: this.SUCCESSFUL_ACCOUNT_CLAIM_MESSAGE,
            modalButtonText: 'Login'
          });
        }, (error: AxiosError) => {
          const state: any = {
            showLoadingOverlay: false,
            showModal: true
          };
  
          if (error.response?.status === 404 || error.response?.status === 409) {
            state.modalText = 'Sorry, that account claim has expired.';
            state.modalButtonText = 'Request new claim';
          } else {
            state.modalText = 'An error occurred. Please try again later.';
            state.modalButtonText = 'Ok';
          }
  
          this.setState(state);
        });
      }
    }
  }

  postAccountClaim() {
    const axios = AxiosSingleton.get();

    const newState: any = {
      showLoadingOverlay: false,
      newAccountClaimAttempted: true,
      showModal: true,
      modalText: undefined,
      modalButtonText: 'Ok'
    };

    axios.post('account-claim', { username: this.props.username }).then(() => {
      newState.modalText = ACCOUNT_CLAIM_SENT_MESSAGE;
      this.setState(newState);
    }, (error: AxiosError) => {
      if (error.response?.status === 409) {
        newState.modalText = this.ACCOUNT_ALREADY_CLAIMED_MESSAGE;
      } else if (error.response?.status === 404) {
        newState.modalText = 'User not found.';
      } else {
        newState.modalText = 'An error occurred. Please try again later.';
      }

      this.setState(newState);
    });
  }

  render() {
    return (
      <AppContext.Consumer>
        {
          ctx => (
            ctx.isLoggedIn
              ? <Navigate to={CONTENT_ROUTE}/>
              :
              <div id="acc-account-claim-container">
                {
                  this.state.navigateToLogin
                    ? <Navigate to='/login'/>
                    : undefined
                }
                {
                  this.state.showLoadingOverlay
                    ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
                    : undefined
                }
                {
                  this.state.showModal
                    ? ReactDOM.createPortal(
                      <ConfirmModal
                        message={this.state.modalText || 'An error occurred.'}
                        confirmText={this.state.modalButtonText || 'Ok'}
                        onClose={() => {
                          if (this.state.modalText === this.SUCCESSFUL_ACCOUNT_CLAIM_MESSAGE || this.state.newAccountClaimAttempted) {
                            this.setState({showModal: false, navigateToLogin: true});
                          } else {
                            this.setState({ showModal: false, showLoadingOverlay: true });
                            this.postAccountClaim();
                          }
                        }} />,
                      document.getElementById('modal-portal-container') as HTMLElement)
                    : undefined
                }
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}
