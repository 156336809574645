import Button from '@mui/material/Button';
import { AxiosError } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConfirmModal } from '../../../common/components/ConfirmModal/ConfirmModal';
import { LoadingOverlay } from '../../../common/components/LoadingOverlay/LoadingOverlay';
import { USERNAME } from '../../../common/cookies';
import AxiosSingleton from '../../../common/web/axios-singleton';
import { ContentType, getContentItemLabel, getContentTypeIcon } from '../../../models/user-content/enum/content-type';
import { UserContent } from '../../../models/user-content/user-content';
import { ContentForm } from '../common/ContentForm/ContentForm';
import { ContentItemProps } from './content-item-props';
import { ContentItemState } from './content-item-state';
import './ContentItem.scss';

export class ContentItem extends React.Component<ContentItemProps, ContentItemState> {
  constructor(props: ContentItemProps) {
    super(props);

    this.state = {
      showOverlay: false,
      showEditForm: false,
      showDeleteModal: false,
      contentItem: this.props.contentItem
    };

    this.handleContentEdit = this.handleContentEdit.bind(this);
  }

  handleContentEdit(contentItem?: UserContent) {
    if (contentItem) {
      this.setState({
        contentItem:  contentItem,
        showEditForm: false
      });
    }
  }

  handleDeleteModalClose(confirmed: boolean) {
    this.setState({ showDeleteModal: false });

    if (confirmed) {
      this.setState({ showOverlay: true });
      const axios = AxiosSingleton.get();
      const username = localStorage.getItem(USERNAME);

      axios.delete(`users/${username}/content/${this.state.contentItem.contentId}`).then(() => {
        this.setState({ showOverlay: false });
        this.props.onDelete(this.state.contentItem.contentId);
      }, (error: AxiosError) => {
        this.setState({ showOverlay: false });
        console.log(error);
      });
    }
  }

  render() {
    return (
      <div id={`${this.state.contentItem.contentType.toString()}-${getContentItemLabel(this.state.contentItem)}`} className='content-item'>
        {
          this.state.showOverlay
            ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
            : <></>
        }
        {
          this.state.showDeleteModal
            ? ReactDOM.createPortal(
              <ConfirmModal
                message='Are you sure you want to delete this content source?'
                confirmText='Yes'
                declineText='No'
                onClose={(confirmed) => { this.handleDeleteModalClose(confirmed); }} />,
              document.getElementById('modal-portal-container') as HTMLElement)
            : <></>
        }
        <div className='content-item-btn-container'>
          {
            [ContentType.YOUTUBE_CHANNEL, ContentType.TWITCH_ACCOUNT, ContentType.URL].includes(this.props.contentItem.contentType)
              ?
              <Button className='content-item-edit-button' onClick={() => { this.setState({ showEditForm: !this.state.showEditForm }); }}>
                { this.state.showEditForm ? 'Cancel' : 'Edit' }
              </Button>
              : <></>
          }
          {
            this.state.showEditForm
              ? <></>
              :
              <Button className='content-item-delete-button' onClick={() => { this.setState({ showDeleteModal: true }); }}>
                Delete
              </Button>
          }
        </div>
        {
          this.state.showEditForm
            ?
            <ContentForm
              contentItem={this.state.contentItem}
              onContentItemProvisioned={(contentItem?: UserContent) => { this.handleContentEdit(contentItem); }} />
            :
            <div className='content-item-info'>
              <div className='content-item-type'>{getContentTypeIcon(this.state.contentItem.contentType.toString(), undefined, {width: 40, height: 40, marginTop: '5px'})}</div>
              <div className={'content-text'}>
                <a href={this.state.contentItem.resolvedURL} target='_blank' rel="noreferrer">
                  { this.state.contentItem.label ? this.state.contentItem.label : this.state.contentItem.externalId }
                </a>
              </div>
            </div>
        }
      </div>
    );
  }
}
