import { CSSProperties, createElement } from 'react';
import { ReactComponent as YouTubeIcon } from '../../../assets/icons/youtube_currentcolor.svg';
import { ReactComponent as TwitchIcon } from '../../../assets/icons/twitch_currentcolor.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/link_currentcolor.svg';
import { ReactComponent as PatreonIcon } from '../../../assets/icons/patreon_currentcolor.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram_currentcolor.svg';
import { ReactComponent as TikTokIcon } from '../../../assets/icons/tiktok_currentcolor.svg';

export enum Icon {
  YOUTUBE = 'youtube',
  TWITCH = 'twitch',
  INSTAGRAM = 'instagram',
  PATREON = 'patreon',
  TIKTOK = 'tiktok',
  LINK = 'link'
}

export const getRoutePageItemIcon = (icon: Icon, styles?: CSSProperties) => {
  if (icon === Icon.YOUTUBE) {
    return createElement(YouTubeIcon, { style: styles });
  } else if (icon == Icon.TIKTOK) {
    return createElement(TikTokIcon, { style: styles });
  } else if (icon === Icon.INSTAGRAM) {
    return createElement(InstagramIcon, { style: styles });
  } else if (icon === Icon.PATREON) {
    return createElement(PatreonIcon, { style: styles });
  } else if (icon === Icon.TWITCH) {
    return createElement(TwitchIcon, { style: styles });
  } else {
    return createElement(LinkIcon, { style: styles });
  }
};
