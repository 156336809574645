import React from 'react';
import './ContentIcon.scss';
import { ContentIconProps } from './content-icon-props';

export class ContentIcon extends React.Component<ContentIconProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      showTooltip: false
    };
  }

  

  render() {
    const iconClass = 'home-content-icon';

    return (
      <div
        className={this.props.className ? [iconClass, this.props.className].join(' ') : iconClass}
        style={this.props.backgroundStyles}>
        { this.props.icon }
      </div>
    );
  }
}
