import './App.scss';
import React, { RefObject, createRef } from 'react';
import { Register } from './components/Register/Register';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import { Login } from './components/Login/Login';
import { Home } from './components/Home/Home';
import { NotFound } from './common/components/NotFound/NotFound';
import AppContext from './common/context/AppContext';
import { AppState } from './app-state';
import { TOKEN } from './common/cookies';
import { Content } from './components/Content/Content';
import { RulesWrapper } from './components/Rules/Rules';
import { Profile } from './components/Profile/Profile';
import { AccountClaimWrapper } from './components/AccountClaim/AccountClaim';
import { LoginHelp } from './components/LoginHelp/LoginHelp';
import { PasswordResetWrapper } from './components/PasswordReset/PasswordReset';
import { ThemeProvider, createTheme } from '@mui/material';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { ACCOUNT_CLAIM_ROUTE, CONTENT_ROUTE, PROFILE_ROUTE, ROUTE_PAGE_ROUTE, RULES_ROUTE, USERS_ROUTE } from './common/app-constants';
import { AuthorizeWrapper } from './components/Authorize/Authorize';
import { RoutePageWrapper } from './components/RoutePage/RoutePage';


export default class App extends React.Component<any, AppState> {
  private readonly HEADER_HEIGHT = 75;
  private readonly ACTIVE_SECTION_SCREEN_THRESHOLD = 0.5;
  private readonly ACTIVE_CLASS = 'active';

  private aboutLinkRef = createRef<HTMLAnchorElement>();
  private aboutMobileLinkRef = createRef<HTMLAnchorElement>();
  private productsLinkRef = createRef<HTMLAnchorElement>();
  private productsMobileLinkRef = createRef<HTMLAnchorElement>();
  private callToActionLinkRef = createRef<HTMLAnchorElement>();
  private callToActionMobileLinkRef = createRef<HTMLAnchorElement>();
  private aboutSectionRef = createRef<HTMLDivElement>();
  private productsSectionRef = createRef<HTMLDivElement>();
  private callToActionSectionRef = createRef<HTMLDivElement>();
  private footerRef = createRef<HTMLDivElement>();

  private THEME = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#4238FF',
      },
      secondary: {
        main: '#FFF'
      }
    },
  });

  constructor(props: any) {
    super(props);

    this.state = {
      isLoggedIn: !!localStorage.getItem(TOKEN)
    };

    this.setLoggedInState = this.setLoggedInState.bind(this);
    this.getWindowTop = this.getWindowTop.bind(this);
    this.getWindowBottom = this.getWindowBottom.bind(this);
    this.onHeaderLinkClick = this.onHeaderLinkClick.bind(this);
  }

  setLoggedInState(value: boolean) {
    this.setState({ isLoggedIn: value });
  }

  getSectionScreenPortion(scrollY: number, windowTop: number, windowBottom: number, sectionBoundingRect?: DOMRect) {
    const totalArea = windowBottom - windowTop;
    const sectionAreaOfScreen =
      Math.min(windowBottom, (sectionBoundingRect?.bottom || Number.POSITIVE_INFINITY) + scrollY) - Math.max(windowTop, (sectionBoundingRect?.top || Number.NEGATIVE_INFINITY) + scrollY);

    const footerBoundingRect = this.footerRef.current?.getBoundingClientRect();

    const footerSectionOfScreen = 
      Math.min(windowBottom, (footerBoundingRect?.bottom || Number.POSITIVE_INFINITY) + scrollY) - Math.min(windowBottom, (footerBoundingRect?.top || Number.NEGATIVE_INFINITY) + scrollY);
    
    return sectionAreaOfScreen / (totalArea - footerSectionOfScreen);
  }

  getWindowTop(scrollY: number) {
    return scrollY - this.HEADER_HEIGHT < 0 ? 0 : scrollY - this.HEADER_HEIGHT;
  }

  getWindowBottom(scrollY: number) {
    return scrollY + window.innerHeight;
  }

  onHeaderLinkClick(bodyRef: RefObject<HTMLDivElement>) {
    const scrollY = window.scrollY;
    const windowTop = this.getWindowTop(scrollY);
    const windowBottom = this.getWindowBottom(scrollY);
    const boundingRect = bodyRef.current?.getBoundingClientRect();
    const areaOfScreen = this.getSectionScreenPortion(scrollY, windowTop, windowBottom, boundingRect);

    if (areaOfScreen < this.ACTIVE_SECTION_SCREEN_THRESHOLD) {
      window.scrollTo({ behavior: 'smooth', top: (boundingRect?.top || 0) + scrollY - this.HEADER_HEIGHT });
    }
  }

  componentDidMount(): void {
    this.aboutLinkRef?.current?.classList.add('active');

    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY;
      const windowTop = this.getWindowTop(scrollY);
      const windowBottom = this.getWindowBottom(scrollY);
      const aboutBoundingRect = this.aboutSectionRef.current?.getBoundingClientRect();
      const productsBoundingRect = this.productsSectionRef.current?.getBoundingClientRect();
      const callToActionBoundingRect = this.callToActionSectionRef.current?.getBoundingClientRect();

      const aboutScreenPortion = this.getSectionScreenPortion(scrollY, windowTop, windowBottom, aboutBoundingRect);
      const productsScreenPortion = this.getSectionScreenPortion(scrollY, windowTop, windowBottom, productsBoundingRect);
      const callToActionScreenPortion = this.getSectionScreenPortion(scrollY, windowTop, windowBottom, callToActionBoundingRect);

      this.aboutLinkRef.current?.classList.remove(this.ACTIVE_CLASS);
      this.aboutMobileLinkRef.current?.classList.remove(this.ACTIVE_CLASS);
      this.productsLinkRef.current?.classList.remove(this.ACTIVE_CLASS);
      this.productsMobileLinkRef.current?.classList.remove(this.ACTIVE_CLASS);
      this.callToActionLinkRef.current?.classList.remove(this.ACTIVE_CLASS);
      this.callToActionMobileLinkRef.current?.classList.remove(this.ACTIVE_CLASS);

      let activeLink;
      let activeMobileLink;

      if (aboutScreenPortion >= this.ACTIVE_SECTION_SCREEN_THRESHOLD) {
        activeLink = this.aboutLinkRef?.current;
        activeMobileLink = this.aboutMobileLinkRef?.current;
      } else if (productsScreenPortion >= this.ACTIVE_SECTION_SCREEN_THRESHOLD) {
        activeLink = this.productsLinkRef?.current;
        activeMobileLink = this.productsMobileLinkRef?.current;
      } else if (callToActionScreenPortion >= this.ACTIVE_SECTION_SCREEN_THRESHOLD) {
        activeLink = this.callToActionLinkRef?.current;
        activeMobileLink = this.callToActionMobileLinkRef?.current;
      }

      if (activeLink) {
        activeLink.classList.add('active');
      }

      if (activeMobileLink) {
        activeMobileLink.classList.add('active');
      }
    });
  }

  render() {
    return (
      <ThemeProvider theme={this.THEME}>
        <AppContext.Provider value={{ isLoggedIn: this.state.isLoggedIn, setIsLoggedIn: (value: boolean) => this.setLoggedInState(value) }}>
          <Router>
            <Header
              aboutLinkRef={this.aboutLinkRef}
              aboutMobileLinkRef={this.aboutMobileLinkRef}
              onAboutLinkClick={() => {
                this.onHeaderLinkClick(this.aboutSectionRef);
              }}
              productsLinkRef={this.productsLinkRef}
              productsMobileLinkRef={this.productsMobileLinkRef}
              onProductsLinkClick={() => {
                this.onHeaderLinkClick(this.productsSectionRef);
              }}
              callToActionLinkRef={this.callToActionLinkRef}
              callToActionMobileLinkRef={this.callToActionMobileLinkRef}
              onCallToActionLinkClick={() => {
                this.onHeaderLinkClick(this.callToActionSectionRef);
              }}
              isLoggedIn={this.state.isLoggedIn}
              onLogout={() => void this.setState({ isLoggedIn: false })} />
            <div id="ac-main-container">
              <Routes>
                <Route
                  path='/'
                  element={
                    <Home
                      aboutSectionRef={this.aboutSectionRef}
                      productsSectionRef={this.productsSectionRef}
                      callToActionSectionRef={this.callToActionSectionRef}/>
                  }
                  errorElement={<NotFound />} />
                <Route path='/login' element={<Login />} errorElement={<NotFound />} />
                <Route path='/register' element={<Register />} errorElement={<NotFound />} />
                <Route path='/login' element={<Login />} errorElement={<NotFound />} />
                <Route path={CONTENT_ROUTE} element={<Content />} errorElement={<NotFound />} />
                <Route path={RULES_ROUTE} element={<RulesWrapper />} errorElement={<NotFound />} />
                <Route path={ROUTE_PAGE_ROUTE} element={<RoutePageWrapper />} errorElement={<NotFound />} />
                <Route path={PROFILE_ROUTE} element={<Profile />} errorElement={<NotFound />} />
                <Route path='/login-help' element={<LoginHelp />} errorElement={<NotFound />} />
                <Route path={`${USERS_ROUTE}/:username${ACCOUNT_CLAIM_ROUTE}/:claimId`} element={<AccountClaimWrapper />} errorElement={<NotFound />} />
                <Route path={`${USERS_ROUTE}/:username/password-reset/:resetId`} element={<PasswordResetWrapper />} errorElement={<NotFound />} />
                <Route path='/authorize/:accountType' element={<AuthorizeWrapper />} errorElement={<NotFound />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </div>
          </Router>
          <Footer footerRef={this.footerRef}/>
        </AppContext.Provider>
      </ThemeProvider>
    );
  }
}
