import { PROD_ENV } from './app-constants';

export const TOKEN = 'token';

export const USERNAME = 'username';

export const setCookie = (name: string, value: string) => {
  
  document.cookie = `${name}=${value};SameSite=Strict;${process.env.REACT_APP_ENV === PROD_ENV ? 'Secure' : ''};Path=/`;
};

export const getCookie = (name: string) => {
  const key = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for(let i = 0; i < cookies.length; i++) {
    let c = cookies[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(key) == 0) {
      return c.substring(key.length, c.length);
    }
  }
  return '';
};
