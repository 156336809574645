import React, { useRef } from 'react';
import { Draggable } from '../../../../models/common/drag-and-drop/draggable';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { DraggableWrapperProps } from './draggable-wrapper-props';
import { getEmptyImage } from 'react-dnd-html5-backend';

export const DraggableWrapper: React.FC<DraggableWrapperProps> = (props: DraggableWrapperProps) => {
  if (props.dragPreview && props.buildItemComponent) {
    return props.buildItemComponent(props, false, undefined, null);
  }

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId: dataHandlerID }, drop] = useDrop<
    Draggable,
    void,
    { handlerId: Identifier | null }
  >({
    accept: props.accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: Draggable, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.item.index;
      
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      if (props.moveItem) {
        props.moveItem(dragIndex, hoverIndex);
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // item.order = hoverIndex;
    },
    drop(item) {
      if (item.index !== item.order && props.onItemDropped) {
        props.onItemDropped();
      }
    }
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: props.accept,
    item: () => {
      return props.item;
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    end(item, monitor) {
      if (!monitor.didDrop() && props.moveItem) {
        props.moveItem(null, null);
      }
    }
  });

  dragPreview(getEmptyImage());

  drag(drop(ref));

  return props.buildItemComponent ? props.buildItemComponent(props, isDragging, ref, dataHandlerID) : <></>;
};
