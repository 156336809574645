import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import { ContentType, contentTypeToLabel, getContentTypeIcon } from '../../../../models/user-content/enum/content-type';
import './ContentForm.scss';
import { ContentFormState } from './content-form-state';
import validateInput, { maxLength, minLength, regEx } from '../../../../common/validation/input-validators';
import { URL_REGULAR_EXPRESSION } from '../../../../common/validation/regular-expressions';
import Button from '@mui/material/Button';
import { LoadingSpinner } from '../../../../common/components/LoadingSpinner/LoadingSpinner';
import AxiosSingleton from '../../../../common/web/axios-singleton';
import { USERNAME } from '../../../../common/cookies';
import { AxiosError, AxiosResponse } from 'axios';
import { ContentFormProps } from './content-form-props';
import { UserContent } from '../../../../models/user-content/user-content';
import { JSONPatchOperation } from '../../../../models/common/web/json-patch-operation';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ReactComponent as OpenInNewIcon } from '../../../../assets/icons/open_in_new.svg';
import { AUTOCOMPLETE_CONTENT_ICON_STYLES } from '../../../../common/app-constants';
import { REQUIRED_FIELD_TEXT } from '../../../../common/text-constants';

export class ContentForm extends React.Component<ContentFormProps, ContentFormState> {
  constructor(props: ContentFormProps) {
    super(props);

    this.onContentTypeSelection = this.onContentTypeSelection.bind(this);
    this.getContentTypeInput = this.getContentTypeInput.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.postContent = this.postContent.bind(this);
    this.patchContent = this.patchContent.bind(this);
    this.getAuthorizationURL = this.getAuthorizationURL.bind(this);
    this.getAuthorizationLabel = this.getAuthorizationLabel.bind(this);
    this.getValidators = this.getValidators.bind(this);

    if (this.props.contentItem) {
      this.state = {
        contentType: this.props.contentItem.contentType,
        label: this.props.contentItem.label || '',
        labelErrorMessage: '',
        externalId: this.props.contentItem.externalId || '',
        externalIdErrorMessage: '',
        url: this.props.contentItem.url || '',
        urlErrorMessage: '',
        contentTypeErrorMessage: '',
        lockInput: false
      };
    } else {
      this.state = {
        contentType: undefined,
        label: '',
        labelErrorMessage: '',
        externalId: '',
        externalIdErrorMessage: '',
        url: '',
        urlErrorMessage: '',
        contentTypeErrorMessage: '',
        lockInput: false
      };
    }
  }

  getValidators(property: string) {
    if (property === 'externalId') {
      return [ minLength(1, `${this.externalIdLabel} is required.`) ];
    } else if (property === 'url') {
      return [ regEx(URL_REGULAR_EXPRESSION, 'Please provide a valid URL.') ];
    } else {
      return [ minLength(1, `${this.labelName} is required.`), maxLength(32) ];
    }
  }

  get externalIdLabel(): string {
    return this.state.contentType === ContentType.YOUTUBE_CHANNEL ? 'Channel ID' : 'Username';
  }

  get labelName(): string {
    return this.state.contentType === ContentType.YOUTUBE_CHANNEL ? 'Channel name' : 'Label';
  }

  get formValid(): boolean {
    let contentValid = false;

    switch(this.state.contentType) {
    case ContentType.TWITCH_ACCOUNT:
      contentValid = !!this.state.externalId && !this.state.externalIdErrorMessage;
      break;
    case ContentType.YOUTUBE_CHANNEL:
      contentValid = !!this.state.externalId && !this.state.externalIdErrorMessage && !!this.state.label && !this.state.labelErrorMessage;
      break;
    default:
      contentValid = !!this.state.url && !this.state.urlErrorMessage && !!this.state.label && !this.state.labelErrorMessage;
      break;
    }

    return contentValid && !!this.state.contentType && !this.state.contentTypeErrorMessage;
  }

  get editFormValuesChanged(): boolean {
    const prev = this.props.contentItem;
    return prev?.contentType !== this.state.contentType
      || (prev?.label !== this.state.label && (!!prev?.label || !!this.state.label))
      || (this.state.contentType === ContentType.URL && prev?.url !== this.state.url)
      || (this.state.contentType !== ContentType.URL && prev?.externalId !== this.state.externalId);
  }

  postContent() {
    this.setState({ lockInput: true });
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    const requestBody: any = {
      contentType: this.state.contentType
    };

    if (this.state.label) {
      requestBody['label'] = this.state.label;
    }

    if (this.state.externalId) {
      requestBody['externalId'] = this.state.externalId;
    }

    if (this.state.url) {
      requestBody['url'] = this.state.url;
    }

    axios.post(`users/${username}/content`, requestBody).then((response: AxiosResponse) => {
      this.setState({ lockInput: false, contentType: undefined, url: '', externalId: '', label: '' });
      this.props.onContentItemProvisioned(response.data as UserContent);
    }, () => {
      this.setState({ lockInput: false });
    });
  }

  patchContent() {
    this.setState({ lockInput: true });

    const requestBody: JSONPatchOperation[] = [];

    const prev = this.props.contentItem;

    if (prev?.contentType !== this.state.contentType) {
      requestBody.push({
        op: 'replace',
        path: '/contentType',
        value: this.state.contentType
      });

      if (this.state.contentType === ContentType.URL) {
        requestBody.push({
          op: 'replace',
          path: '/externalId',
          value: null
        });
      } else {
        requestBody.push({
          op: 'replace',
          path: '/url',
          value: null
        });
      }
    }

    if (prev?.label !== this.state.label && (prev?.label || this.state.label)) {
      requestBody.push({
        op: 'replace',
        path: '/label',
        value: this.state.label
      });
    }

    if (this.state.contentType === ContentType.URL && prev?.url !== this.state.url) {
      requestBody.push({
        op: 'replace',
        path: '/url',
        value: this.state.url
      });
    } else if (prev?.externalId !== this.state.externalId) {
      requestBody.push({
        op: 'replace',
        path: '/externalId',
        value: this.state.externalId
      });
    }

    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);
    
    axios.patch(`users/${username}/content/${prev?.contentId}`, requestBody).then((response: AxiosResponse) => {
      console.log(response);
      this.setState({ lockInput: false });
      this.props.onContentItemProvisioned(response.data as UserContent);
    }, (error: AxiosError) => {
      console.log(error);
      this.setState({ lockInput: false });
    });
  }

  onContentTypeSelection(selection: any) {
    const newState: any = {
      label: '',
      labelErrorMessage: '',
      externalId: '',
      externalIdErrorMessage: ''
    };

    if (selection) {
      newState['contentType'] = selection.value;
      newState['contentTypeErrorMessage'] = '';
    } else {
      newState['contentType'] = undefined;
      newState['contentTypeErrorMessage'] = 'Content type is required.';
    }

    this.setState(newState);
  }

  handleInput(newState: any) {
    const property = Object.keys(newState)[0];
    newState[property + 'ErrorMessage'] = validateInput(newState[property], this.getValidators(property));
    this.setState(newState);
  }

  getAuthorizationURL() {
    if (this.state.contentType === ContentType.PATREON_CAMPAIGN) {
      return `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_PATREON_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_PATREON_REDIRECT_URL}`;
    } else if (this.state.contentType === ContentType.TIKTOK_ACCOUNT) {
      return `https://www.tiktok.com/v2/auth/authorize?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_KEY}&response_type=code&scope=user.info.basic,user.info.profile,video.list&redirect_uri=${process.env.REACT_APP_TIKTOK_REDIRECT_URL}`;
    } else {
      return `https://api.instagram.com/oauth/authorize?client_id=${process.env.REACT_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_INSTAGRAM_REDIRECT_URL}&scope=user_profile,user_media&response_type=code`;
    }
  }

  getAuthorizationLabel() {
    if (this.state.contentType === ContentType.PATREON_CAMPAIGN) {
      return 'Patreon';
    } else if (this.state.contentType === ContentType.TIKTOK_ACCOUNT) {
      return 'TikTok';
    } else {
      return 'Instagram';
    }
  }

  getContentTypeInput() {
    if (this.state.contentType) {
      let input: any;

      if (this.state.contentType === ContentType.TWITCH_ACCOUNT || this.state.contentType === ContentType.YOUTUBE_CHANNEL) {
        input =
        <FormControl
          id='cfc-external-id-form-control'
          className='content-input'
          error={!!this.state.externalIdErrorMessage}>
          <InputLabel
            htmlFor='cfc-external-id-input'
            id="cfc-external-id-input-label">
            {this.externalIdLabel + '*'}
          </InputLabel>
          <OutlinedInput
            id="cfc-external-id-input"
            label={this.externalIdLabel + '*'}
            value={this.state.externalId || ''}
            disabled={this.state.lockInput}
            onChange={(event) => void this.handleInput({ externalId: event.target.value })}/>
          <FormHelperText
            id="cfc-external-id-input-helper-text">
            {this.state.externalIdErrorMessage ? this.state.externalIdErrorMessage : REQUIRED_FIELD_TEXT}
            {
              this.state.contentType === ContentType.YOUTUBE_CHANNEL
                ?
                <div id="cfc-external-id-help">
                  <a href="https://support.google.com/youtube/answer/3250431?hl=en" target="_blank" rel="noreferrer">
                    <span>Where can I find this?</span>
                    <OpenInNewIcon id='cfc-external-id-help-icon'/>
                  </a>
                </div>
                : undefined
            }
          </FormHelperText>
        </FormControl>;
      } else if (this.state.contentType === ContentType.URL) {
        input = <TextField
          id="cfc-url-input"
          label="URL*"
          variant="outlined"
          className='content-input'
          value={this.state.url || ''}
          error={!!this.state.urlErrorMessage}
          disabled={this.state.lockInput}
          onChange={(event) => void this.handleInput({ url: event.target.value })}
          helperText={this.state.urlErrorMessage || REQUIRED_FIELD_TEXT} />;
      }

      return input;
    } else {
      return <></>;
    }
  }

  render() {
    const contentTypes: { label: string, value: string}[] = Object.keys(ContentType).map(x => { return { label: contentTypeToLabel(x), value: x }; });
    const containerId = !this.props.contentItem ? 'cfc-add-content-container' : `cfc-edit-content-container-${this.props.contentItem.contentId}`;
    const buttonText = !this.props.contentItem ? 'Add Content' : 'Update';

    return (
      <div id={containerId} className='cfc-content-form-container'>
        <Autocomplete
          disablePortal
          id="cfc-content-type-autocomplete"
          options={contentTypes}
          className='content-input'
          disabled={this.state.lockInput || !!this.props.contentItem}
          value={this.state.contentType ? { label: contentTypeToLabel(this.state.contentType), value: this.state.contentType } : null }
          isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
          onChange={(event, value) => { this.onContentTypeSelection(value); }}
          renderOption={(props, option) => {
            return (
              <li key={option.value} {...props}>
                { getContentTypeIcon(option.value, undefined, AUTOCOMPLETE_CONTENT_ICON_STYLES) }&nbsp;&nbsp;
                { option.label }
              </li>
            );
          }}
          renderInput={(params) => (
            <>
              <TextField {...params}
                label="Content Type*"
                error={!!this.state.contentTypeErrorMessage}
                helperText={this.state.contentTypeErrorMessage || REQUIRED_FIELD_TEXT}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: 
                    !this.state.contentType
                      ? undefined
                      :
                      <InputAdornment position='start'>
                        {getContentTypeIcon(this.state.contentType?.toString(), undefined, AUTOCOMPLETE_CONTENT_ICON_STYLES)}
                      </InputAdornment>
                }}/>
            </>
          )}
        />

        {
          this.state.contentType === ContentType.YOUTUBE_CHANNEL
            || this.state.contentType === ContentType.URL
            ?
            <TextField
              id="cfc-label-input"
              label={`${this.labelName}*`}
              variant="outlined"
              className='label-input'
              value={this.state.label || ''}
              error={!!this.state.labelErrorMessage}
              disabled={this.state.lockInput}
              onChange={(event) => void this.handleInput({ label: event.target.value })}
              helperText={this.state.labelErrorMessage ? this.state.labelErrorMessage : REQUIRED_FIELD_TEXT} />
            : <></>
        }

        { this.getContentTypeInput() }

        {
          (this.state.contentType === ContentType.PATREON_CAMPAIGN
            || this.state.contentType === ContentType.TIKTOK_ACCOUNT)
            && !this.props.contentItem
            ?
            <div className='cfc-authorize-link-container'>
              <a
                className='cfc-authorize-link'
                onClick={() => {
                  this.props.onContentItemProvisioned(undefined);
                  return true;
                }} 
                rel="noreferrer"
                href={this.getAuthorizationURL()}
                target="_blank">
                <span>Authorize with { this.getAuthorizationLabel() }&nbsp;</span>
                <OpenInNewIcon />
              </a>
            </div>
            :
            <div className='cfc-save-content-btn-container'>
              <Button className='save-content-btn'
                disabled={this.state.lockInput || !this.formValid || (!!this.props.contentItem && !this.editFormValuesChanged)}
                variant='contained'
                onClick={() => {
                  if (this.props.contentItem) {
                    this.patchContent();
                  } else {
                    this.postContent();
                  }
                }}>
                { this.state.lockInput ? <LoadingSpinner size={15} /> : buttonText}
              </Button>
            </div>
        }
      </div>
    );
  }
}
