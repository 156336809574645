import React, { CSSProperties } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { Draggable } from '../../../../models/common/drag-and-drop/draggable';
import { DragLayerProps } from './drag-layer-props';
import { UserRule } from '../../../../models/user-rule/user-rule';
import { DRAGGABLE_RULE_TYPE } from '../../../app-constants';
import { UserRoutePageItem } from '../../../../models/user-route-page/user-route-page-item';


export const DragLayer: React.FC<DragLayerProps> = (props) => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer<
    { isDragging: boolean, item: Draggable, initialOffset: XYCoord | null, currentOffset: XYCoord | null }
  >((monitor) => {
    return {
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
    };
  });

  const layerStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: 'calc(100% - 50px)',
    height: '100%',
    maxWidth: '600px'
  };

  const getItemStyles = (
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null
  ) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
  
    const { x, y } = currentOffset;
  
    const transform = `translate(${x}px, ${y + props.yOffsetAdjust}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  };

  const getItemID = (accept: string, item: Draggable) => {
    if (accept === DRAGGABLE_RULE_TYPE) {
      return (item as UserRule).ruleId;
    } else {
      return (item as UserRoutePageItem).routePageItemID;
    }
  };

  return !isDragging
    ? null
    : 
    (
      <div style={layerStyles}>
        <div
          style={getItemStyles(initialOffset, currentOffset)}>
          { props.buildItemComponent(getItemID(props.accept, item)) }
        </div>
      </div>
    );
};
