import Button from '@mui/material/Button';
import React from 'react';
import { ColorPickerState } from './color-picker-state';
import { ColorPickerProps } from './color-picker-props';
import { SketchPicker, RGBColor } from 'react-color';
import './ColorPicker.scss';

export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  constructor(props: ColorPickerProps) {
    super(props);

    this.state = {
      showColorPicker: false,
      selectedColor: props.selectedColor
    };

    this.toggleColorPicker = this.toggleColorPicker.bind(this);
    this.rgbToString = this.rgbToString.bind(this);
  }

  toggleColorPicker() {
    this.setState({ showColorPicker: !this.state.showColorPicker });
  }

  rgbToString(color: RGBColor) {
    return `rgb(${[color.r, color.g, color.b].join(', ')})`;
  }

  render() {
    return (
      <div id={this.props.id} className='color-picker-container'>
        <span>{this.props.label}:&nbsp;</span>
        <Button
          id={this.props.id + '-btn'}
          className='color-select-btn'
          color='secondary'
          variant='contained'
          onClick={() => void this.toggleColorPicker()}>
          <div
            className='selected-color'
            style={{backgroundColor: this.state.selectedColor}}>
          </div>
        </Button>
        {
          this.state.showColorPicker
            ?
            <div className='color-picker-wrapper'>
              <SketchPicker
                color={this.state.selectedColor}
                disableAlpha={true}
                onChangeComplete={(color) => {
                  this.setState({ selectedColor: this.rgbToString(color.rgb) });
                }}/>
              <div className='color-picker-button-container'>
                <Button
                  className='color-picker-save-btn'
                  disabled={this.props.selectedColor === this.state.selectedColor}
                  variant='contained'
                  onClick={() => {
                    this.props.onChangeComplete(this.state.selectedColor);
                    this.toggleColorPicker();
                  }}>
                  Ok
                </Button>
                <Button
                  className='color-picker-cancel-btn'
                  variant='outlined'
                  onClick={() => {
                    this.setState({ selectedColor: this.props.selectedColor });
                    this.toggleColorPicker();
                  }}>
                  Cancel
                </Button>
              </div>
            </div>
            : undefined
        }
      </div>
    );
  }
}
