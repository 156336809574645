import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Navigate } from 'react-router-dom';
import { ConfirmModal } from '../../common/components/ConfirmModal/ConfirmModal';
import { LoadingOverlay } from '../../common/components/LoadingOverlay/LoadingOverlay';
import AppContext from '../../common/context/AppContext';
import { USERNAME } from '../../common/cookies';
import { PASSWORD_ERROR_MESSAGE } from '../../common/validation/constants';
import validateInput, { maxLength, minLength, regEx } from '../../common/validation/input-validators';
import { EMAIL_REGULAR_EXPRESSION, PASSWORD_REGULAR_EXPRESSION } from '../../common/validation/regular-expressions';
import AxiosSingleton from '../../common/web/axios-singleton';
import { UserPatchRequest } from '../../models/common/web/user-patch-request';
import { UserInfo } from '../../models/user-info';
import { DisplayName } from './DisplayName/DisplayName';
import { Email } from './Email/Email';
import { ProfileState } from './profile-state';
import './Profile.scss';


export class Profile extends React.Component<any, ProfileState> {
  private VALIDATORS = {
    email: [ regEx(EMAIL_REGULAR_EXPRESSION, 'Please provide a valid email address.') ],
    displayName: [ minLength(1), maxLength(32) ],
    password: [ regEx(PASSWORD_REGULAR_EXPRESSION,PASSWORD_ERROR_MESSAGE), maxLength(16) ]
  };

  private NEW_PASSWORD_MATCHES_CURRENT_ERROR_TEXT = 'Please provide a password that does not match your current one.';

  private NEW_PASSWORDS_MISMATCH_ERROR_TEXT = 'New passwords do not match.';

  constructor(props: any) {
    super(props);

    this.state = {
      editDisplayName: false,
      editEmail: false,
      showLoadingOverlay: false,
      changePassword: false,
      showPasswordChangedModal: false
    };

    this.handleInput = this.handleInput.bind(this);
    this.patchUser = this.patchUser.bind(this);
  }

  get changePasswordFormInvalid(): boolean {
    return !this.state.currentPassword || !!this.state.currentPasswordErrorMessage
      || !this.state.newPassword || !!this.state.newPasswordErrorMessage
      || !this.state.confirmNewPassword || !!this.state.confirmNewPasswordErrorMessage;
  }

  handleInput(value: { email?: string, displayName?: string, currentPassword?: string, newPassword?: string, confirmNewPassword?: string }) {
    if (value.email || value.email === '') {
      const errorMessage = validateInput(value.email, this.VALIDATORS.email);
      this.setState({
        email: value.email,
        emailErrorMessage: errorMessage
      });
    } else if (value.displayName || value.displayName === '') {
      const errorMessage = validateInput(value.displayName, this.VALIDATORS.displayName);
      this.setState({
        displayName: value.displayName,
        displayNameErrorMessage: errorMessage
      });
    } else {
      if (value.currentPassword || value.currentPassword === '') {
        const error = validateInput(value.currentPassword, this.VALIDATORS.password);

        this.setState({
          currentPassword: value.currentPassword, 
          currentPasswordErrorMessage: error,
          newPasswordErrorMessage: this.state.newPassword && value.currentPassword === this.state.newPassword
            ? this.NEW_PASSWORD_MATCHES_CURRENT_ERROR_TEXT
            : ''
        });
      } else if (value.newPassword || value.newPassword === '') {
        let error = validateInput(value.newPassword, this.VALIDATORS.password);

        if (!error && this.state.currentPassword === value.newPassword) {
          error = this.NEW_PASSWORD_MATCHES_CURRENT_ERROR_TEXT;
        }

        this.setState({
          newPassword: value.newPassword,
          newPasswordErrorMessage: error,
          confirmNewPasswordErrorMessage: this.state.confirmNewPassword && value.newPassword !== this.state.confirmNewPassword
            ? this.NEW_PASSWORDS_MISMATCH_ERROR_TEXT
            : ''
        });
      } else {
        let error = validateInput(value.confirmNewPassword || '', this.VALIDATORS.password);

        if (!error && value.confirmNewPassword !== this.state.newPassword) {
          error = this.NEW_PASSWORDS_MISMATCH_ERROR_TEXT;
        }

        this.setState({ confirmNewPassword: value.confirmNewPassword, confirmNewPasswordErrorMessage: error });
      }
    }
  }

  componentDidMount(): void {
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    if (username) {
      this.setState({showLoadingOverlay: true});
      axios.get(`users/${username}`).then((response: AxiosResponse) => {
        const userInfo = response.data as UserInfo;
        
        this.setState({
          userInfo: userInfo,
          showLoadingOverlay: false,
          email: userInfo.email,
          displayName: userInfo.displayName
        });
      }, () => {
        this.setState({
          showLoadingOverlay: false
        });
      });
    }
  }

  patchUser(value: { displayName?: string, email?: string, password?: string }) {
    this.setState({showLoadingOverlay: true});

    if (value.displayName) {
      const request: UserPatchRequest = {
        patch: [{ op: 'replace', path: '/displayName', value: value.displayName }]
      };

      const username = localStorage.getItem(USERNAME);
      const axios = AxiosSingleton.get();

      axios.patch(`users/${username}`, request).then((response: AxiosResponse) => {
        this.setState({
          userInfo: response.data,
          showLoadingOverlay: false,
          editDisplayName: false
        });
      }, () => {
        this.setState({ showLoadingOverlay: false });
      });
    } else if (value.email) {
      const request: UserPatchRequest = {
        patch: [{ op: 'replace', path: '/email', value: value.email }]
      };

      const username = localStorage.getItem(USERNAME);
      const axios = AxiosSingleton.get();

      axios.patch(`users/${username}`, request).then((response: AxiosResponse) => {
        this.setState({
          userInfo: response.data,
          showLoadingOverlay: false,
          editEmail: false
        });
      }, () => {
        this.setState({ showLoadingOverlay: false });
      });
    } else if (value.password) {
      const request: UserPatchRequest = {
        password: this.state.currentPassword,
        patch: [{ op: 'replace', path: '/password', value: value.password }]
      };

      const username = localStorage.getItem(USERNAME);
      const axios = AxiosSingleton.get();

      axios.patch(`users/${username}`, request).then((response: AxiosResponse) => {
        this.setState({
          userInfo: response.data,
          showLoadingOverlay: false,
          changePassword: false,
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
          showPasswordChangedModal: true
        });
      }, (error: AxiosError) => {
        const state: any = {
          showLoadingOverlay: false
        };

        if (error.response?.status === 400) {
          state['currentPasswordErrorMessage'] = 'Invalid current password.';
        }

        this.setState(state);
      });
    }
  }

  render() {
    return (
      <AppContext.Consumer>
        {
          ctx => (
            !ctx.isLoggedIn
              ? <Navigate to='/login'/>
              :
              <div id="pc-profile-container" className='mobile-drawer-offset'>
                {
                  this.state.showLoadingOverlay
                    ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
                    : undefined
                }
                {
                  this.state.showPasswordChangedModal
                    ? ReactDOM.createPortal(
                      <ConfirmModal
                        message='Password changed successfully.'
                        confirmText='Ok'
                        onClose={() => void this.setState({showPasswordChangedModal: false})} />,
                      document.getElementById('modal-portal-container') as HTMLElement)
                    : <></>
                }
                <DisplayName 
                  displayName={this.state.displayName || ''}
                  displayNameErrorMessage={this.state.displayNameErrorMessage || ''}
                  staticDisplayName={this.state.userInfo?.displayName || ''}
                  editDisplayName={this.state.editDisplayName}
                  displayNameChanged={(value: string) => void this.handleInput({ displayName: value })}
                  displayNameSaved={() => void this.patchUser({displayName: this.state.displayName})}
                  editButtonClicked={() => {
                    if (this.state.editDisplayName) {
                      this.setState({ editDisplayName: !this.state.editDisplayName, displayName: this.state.userInfo?.displayName, displayNameErrorMessage: '' });
                    } else {
                      this.setState({ editDisplayName: !this.state.editDisplayName });
                    }
                  }}/>        
                <Email
                  email={this.state.email || ''}
                  emailErrorMessage={this.state.emailErrorMessage || ''}
                  staticEmail={this.state.userInfo?.email || ''}
                  editEmail={this.state.editEmail}
                  emailChanged={(value: string) => void this.handleInput({ email: value })}
                  emailSaved={() => void this.patchUser({email: this.state.email})}
                  editButtonClicked={() => {
                    if (this.state.editEmail) {
                      this.setState({ editEmail: !this.state.editEmail, email: this.state.userInfo?.email, emailErrorMessage: '' });
                    } else {
                      this.setState({ editEmail: !this.state.editEmail });
                    }
                  }} />
                  
                <div id="pc-password-container" className='static-field-container'>
                  <Button
                    id="pc-change-password-btn"
                    variant={this.state.changePassword ? 'outlined' : 'contained'}
                    onClick={() => {
                      if (this.state.changePassword) {
                        this.setState({ changePassword: !this.state.changePassword });
                      } else {
                        this.setState({
                          changePassword: !this.state.changePassword,
                          currentPassword: '',
                          currentPasswordErrorMessage: '',
                          newPassword: '',
                          newPasswordErrorMessage: '',
                          confirmNewPassword: '',
                          confirmNewPasswordErrorMessage: ''
                        });
                      }
                    }}>
                    { this.state.changePassword ? 'Cancel' : 'Change Password' }
                  </Button>
                  {
                    this.state.changePassword
                      ?
                      <div id="pc-change-password-container">
                        <TextField
                          id="pc-password-input"
                          className='text-input'
                          label="Current password"
                          variant='outlined'
                          type="password"
                          value={this.state.currentPassword}
                          autoComplete="new-password"
                          onChange={(event) => void this.handleInput({ currentPassword: event.target.value })}
                          error={!!this.state.currentPasswordErrorMessage}
                          helperText={this.state.currentPasswordErrorMessage} />
                        <TextField
                          id="pc-new-password-input"
                          className='text-input'
                          label="New password"
                          variant='outlined'
                          type="password"
                          value={this.state.newPassword}
                          autoComplete="new-password"
                          onChange={(event) => void this.handleInput({ newPassword: event.target.value })}
                          error={!!this.state.newPasswordErrorMessage}
                          helperText={this.state.newPasswordErrorMessage} />
                        <TextField
                          id="pc-confirm-new-password-input"
                          className='text-input'
                          label="Confirm new password"
                          variant='outlined'
                          type="password"
                          value={this.state.confirmNewPassword}
                          autoComplete="new-password"
                          onChange={(event) => void this.handleInput({ confirmNewPassword: event.target.value })}
                          error={!!this.state.confirmNewPasswordErrorMessage}
                          helperText={this.state.confirmNewPasswordErrorMessage} />
                        <Button
                          id="pc-save-password-btn"
                          variant='contained'
                          disabled={this.changePasswordFormInvalid}
                          onClick={() => void this.patchUser({ password: this.state.confirmNewPassword })}>
                          Save
                        </Button>
                      </div>
                      : <></>
                  }
                </div>
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}
