import React, { MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useSearchParams } from 'react-router-dom';
import { HeaderProps } from './header-props';
import { Link as RouterLink } from 'react-router-dom';
import AxiosSingleton from '../../common/web/axios-singleton';
import { TOKEN, USERNAME } from '../../common/cookies';
import { AxiosError } from 'axios';
import './Header.scss';
import {
  ABOUT_SECTION_ID,
  CONTENT_ROUTE,
  GET_STARTED_SECTION_ID as CALL_TO_ACTION_SECTION_ID,
  PROFILE_ROUTE,
  REGISTER_ROUTE,
  RULES_ROUTE,
  ROUTE_PAGE_ROUTE,
  CDN_URL, 
  PRODUCTS_SECTION_ID
} from '../../common/app-constants';
import { LoadingOverlay } from '../../common/components/LoadingOverlay/LoadingOverlay';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';


export const Header = (props: HeaderProps) => {
  const SCROLL_PARAM = 'scroll';
  const MENU_ITEM_HREF_PREFIX = `?${SCROLL_PARAM}=`;
  const HEADER_ID = 'hec-header';
  const MOBILE_DRAWER_ID = 'hec-mobile-drawer';
  const ACTIVE_LINK_CLASS_NAME = 'active';
  const LOGIN_ROUTE = '/login';

  const location = useLocation();
  const pathname = location.pathname;

  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const contentLinkRef = useRef<HTMLAnchorElement>(null);
  const rerouteUrlLinkRef = useRef<HTMLAnchorElement>(null);
  const routePageLinkRef = useRef<HTMLAnchorElement>(null);
  const profileLinkRef = useRef<HTMLAnchorElement>(null);

  const markLinkActive = (event: MouseEvent<HTMLAnchorElement>) => {
    window.scroll({ top: 0, behavior: 'auto' });

    contentLinkRef.current?.classList.remove(ACTIVE_LINK_CLASS_NAME);
    rerouteUrlLinkRef.current?.classList.remove(ACTIVE_LINK_CLASS_NAME);
    routePageLinkRef.current?.classList.remove(ACTIVE_LINK_CLASS_NAME);
    profileLinkRef.current?.classList.remove(ACTIVE_LINK_CLASS_NAME);

    const link = event.target as HTMLElement;
    if (link && !link.classList.contains(ACTIVE_LINK_CLASS_NAME)) {
      link.classList.add(ACTIVE_LINK_CLASS_NAME);
    }
  };

  const notOnHome = () => {
    return !!pathname && pathname != '/';
  };

  const onLoginOrRegisterPage = () => {
    return !!pathname && (pathname === LOGIN_ROUTE || pathname === REGISTER_ROUTE);
  };

  const [scrollParam] = useSearchParams();
  useEffect(() => {
    if (scrollParam) {
      const value = scrollParam.get(SCROLL_PARAM);

      if (value === ABOUT_SECTION_ID) {
        props.onAboutLinkClick();
      } else if (value === PRODUCTS_SECTION_ID) {
        props.onProductsLinkClick();
      } else if (value === CALL_TO_ACTION_SECTION_ID) {
        props.onCallToActionLinkClick();
      }
    }
  }, [scrollParam]);

  const logout = () => {
    setShowLoadingOverlay(true);
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    axios.delete(`login/${username}`).then(() => {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(USERNAME);

      setShowLoadingOverlay(false);
      props.onLogout();
    }, (error: AxiosError) => {
      console.log(error);
    });
  };

  const toggleDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const buildRouterLink = (id: string, to: string, ref: RefObject<HTMLAnchorElement>, onClick: () => void, label: string) => {
    return (
      <RouterLink
        id={id}
        key={id}
        to={to}
        className='link'
        ref={ref}
        onClick={onClick}>
        { label }
      </RouterLink>
    );
  };

  const ABOUT_LINK_ID = 'hec-about-link';
  const ABOUT_LINK_TO = `${MENU_ITEM_HREF_PREFIX}${ABOUT_SECTION_ID}`;
  const ABOUT_LINK_LABEL = 'About';
  const PRODUCTS_LINK_ID = 'hec-products-link';
  const PRODUCTS_LINK_TO = `${MENU_ITEM_HREF_PREFIX}${PRODUCTS_SECTION_ID}`;
  const PRODUCTS_LINK_LABEL = 'Products';
  const CALL_TO_ACTION_LINK_ID = 'hec-get-started-link';
  const CALL_TO_ACTION_LINK_TO = `${MENU_ITEM_HREF_PREFIX}${CALL_TO_ACTION_SECTION_ID}`;
  const CALL_TO_ACTION_LINK_LABEL = 'Get Started';

  const loginLogout =
    props.isLoggedIn
      ?
      <button id='hec-logout-btn' key='hec-logout-btn' className='login-logout' onClick={logout}>
        Logout
      </button>
      :
      notOnHome()
        ? undefined
        :
        <RouterLink id='hec-login-link' key='hec-login-link' className='login-logout' to={LOGIN_ROUTE}>
          Login
        </RouterLink>;

  const buildMenuItems = (mobile?: boolean) => {
    return !props.isLoggedIn
      ? notOnHome()
        ? []
        :
        [
          buildRouterLink(
            ABOUT_LINK_ID,
            ABOUT_LINK_TO,
            mobile ? props.aboutMobileLinkRef : props.aboutLinkRef,
            props.onAboutLinkClick,
            ABOUT_LINK_LABEL
          ),
          buildRouterLink(
            PRODUCTS_LINK_ID,
            PRODUCTS_LINK_TO,
            mobile ? props.productsMobileLinkRef : props.productsLinkRef,
            props.onProductsLinkClick,
            PRODUCTS_LINK_LABEL
          ),
          buildRouterLink(
            CALL_TO_ACTION_LINK_ID,
            CALL_TO_ACTION_LINK_TO,
            mobile ? props.callToActionMobileLinkRef : props.callToActionLinkRef,
            props.onCallToActionLinkClick,
            CALL_TO_ACTION_LINK_LABEL
          )
        ]
      : [
        <RouterLink
          id='hec-content-link'
          key='hec-content-link'
          className={`link${pathname === CONTENT_ROUTE ? ' active' : ''}`}
          to={CONTENT_ROUTE}
          ref={contentLinkRef}
          onClick={(event) => void markLinkActive(event)}>
          Content sources
        </RouterLink>,
        <RouterLink
          id='hec-rules-link'
          key='hec-rules-link'
          className={`link${pathname === RULES_ROUTE ? ' active' : ''}`}
          to={RULES_ROUTE}
          ref={rerouteUrlLinkRef}
          onClick={(event) => void markLinkActive(event)}>
          Reroute URL
        </RouterLink>,
        <RouterLink
          id='hec-route-page-link'
          key='hec-route-page-link'
          className={`link${pathname === ROUTE_PAGE_ROUTE ? ' active' : ''}`}
          to={ROUTE_PAGE_ROUTE}
          ref={routePageLinkRef}
          onClick={(event) => void markLinkActive(event)}>
          Route Page
        </RouterLink>,
        <RouterLink
          id='hec-profile-link'
          key='hec-profile-link'
          className={`link${pathname === PROFILE_ROUTE ? ' active' : ''}`}
          to={PROFILE_ROUTE}
          ref={profileLinkRef}
          onClick={(event) => void markLinkActive(event)}>
          Profile
        </RouterLink>
      ];
  };

  return (
    <>
      {
        showLoadingOverlay
          ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
          : null
      }
      <div id="hec-header-spacing"></div>
      <div id={HEADER_ID}>
        <div id="hec-header-inner">
          <RouterLink to='/'>
            <img id="hec-logo" src={( CDN_URL) + 'img/reroute-logo.png'} alt="Reroute logo"/>
          </RouterLink>
          <div id="hec-header-links" className='desktop'>
            { buildMenuItems() }
          </div>
          <div className='desktop'>
            { loginLogout }
          </div>
          {
            onLoginOrRegisterPage()
              ? undefined
              :
              <button
                id="hec-mobile-menu-btn"
                className='mobile'
                onClick={() => void toggleDrawer()}>
                {
                  mobileDrawerOpen
                    ? <CloseIcon id="hec-close" />
                    : <MenuIcon />
                }
              </button>
          }
        </div>
      </div>
      {
        !onLoginOrRegisterPage() && 
          <Drawer
            id={MOBILE_DRAWER_ID}
            variant='persistent'
            anchor='top'
            className={`mobile${mobileDrawerOpen ? '' : ' closed'}`}
            open={mobileDrawerOpen}
            onClose={() => void toggleDrawer()}>
            <List>
              { buildMenuItems(true).map((x, i) => <ListItem key={i} onClick={() => void toggleDrawer()}>{x}</ListItem>) }
              { loginLogout && <ListItem onClick={() => void toggleDrawer()}>{loginLogout}</ListItem> }
            </List>
          </Drawer>
      }
    </>
  );
};
