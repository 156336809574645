import React from 'react';
import './NotFound.scss';

export class NotFound extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div id="nfc-not-found-container">
        <h1>Oops</h1>
        <p>Sorry, but that doesn&apos;t exist.</p>
      </div>
    );
  }
}