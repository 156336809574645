import React from 'react';
import './Footer.scss';
import { CDN_URL } from '../../common/app-constants';
import { FooterProps } from './footer-props';

export class Footer extends React.Component<FooterProps> {
  constructor(props: FooterProps) {
    super(props);
  }

  render() {
    const version = process.env.REACT_APP_VERSION;
    const cdnURL =  CDN_URL;

    return (
      <div id="fc-footer-wrapper" ref={this.props.footerRef}>
        <div id="fc-curved-block"></div>
        <div id="fc-curved-block-background"></div>
        <div id="fc-footer">
          <div id="fc-top-container">
            <div id="fc-left-container">
              <img src={cdnURL + 'img/reroute-logo.png'} alt="Reroute logo"/>
            </div>
            <div id="fc-right-container">
              <div id="fc-important-links">
                <p>Important Links</p>
                <a href={cdnURL + 'html/privacy.html'} target="_blank" rel="noreferrer">Privacy Policy</a>
                <a href={cdnURL + 'html/terms.html'} target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                <a href={cdnURL + 'html/cookies.html'} target="_blank" rel="noreferrer">Cookie Policy</a>
              </div>
            </div>
          </div>
          <div id="fc-bottom-container">
            <span>
              Reroute LLC | All rights reserved
            </span>
            <p>
              { version }
            </p>
          </div>
        </div>
      </div>
    );
  }
}
