import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './LoadingOverlay.scss';

export class LoadingOverlay extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div id="loc-loading-overlay-container">
        <LoadingSpinner size={64} />
      </div>
    );
  }
}
