/* eslint-disable no-useless-escape */
export const EMAIL_REGULAR_EXPRESSION = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export const PASSWORD_REGULAR_EXPRESSION = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?\/~_\+\-=|]).{8,}$/);

export const USERNAME_REGULAR_EXPRESSION = new RegExp(/^[A-Za-z0-9][\w-]*$/);

export const URL_REGULAR_EXPRESSION = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&/=]*)/);

export const CONDITION_VALUE_REGULAR_EXPRESSION = new RegExp(/^[0-9]+?$/);
