import React from 'react';
import './Home.scss';
import { About } from './About/About';
import { CallToAction } from './CallToAction/CallToAction';
import AppContext from '../../common/context/AppContext';
import { Navigate } from 'react-router-dom';
import { CONTENT_ROUTE } from '../../common/app-constants';
import { Products } from './Products/Products';
import { HomeProps } from './home-props';

export class Home extends React.Component<HomeProps> {

  constructor(props: HomeProps) {
    super(props);
  }

  render() {
    return (
      <AppContext.Consumer>
        {
          ctx => (
            ctx.isLoggedIn
              ? <Navigate to={CONTENT_ROUTE}/>
              :
              <>
                <div id="hc-background-block"></div>
                <div id="hc-home-container" className='mobile-drawer-offset'>
                  <About aboutSectionRef={this.props.aboutSectionRef}/>
                  <Products productsRef={this.props.productsSectionRef}/>
                  <CallToAction callToActionRef={this.props.callToActionSectionRef}/>
                </div>
              </>
          )
        }
      </AppContext.Consumer>
    );
  }

}
