import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React from 'react';
import validateInput, { max, min, regEx } from '../../../../common/validation/input-validators';
import { CONDITION_VALUE_REGULAR_EXPRESSION } from '../../../../common/validation/regular-expressions';
import { ContentType } from '../../../../models/user-content/enum/content-type';
import { UserContent } from '../../../../models/user-content/user-content';
import { ContentTarget, contentTargetToLabel, getContentTargetFilterForContentType } from '../../../../models/user-rule/enum/content-target';
import { getTargetConditionFilterForContentTypeAndTarget, TargetCondition, targetConditionToLabel } from '../../../../models/user-rule/enum/target-condition';
import { RulesFormProps } from './rules-form-props';
import { RulesFormState } from './rules-form-state';
import AxiosSingleton from '../../../../common/web/axios-singleton';
import { USERNAME } from '../../../../common/cookies';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingSpinner } from '../../../../common/components/LoadingSpinner/LoadingSpinner';
import { UserRule } from '../../../../models/user-rule/user-rule';
import './RulesForm.scss';
import { JSONPatchOperation } from '../../../../models/common/web/json-patch-operation';
import { REQUIRED_FIELD_TEXT } from '../../../../common/text-constants';
import { ContentAutocomplete } from '../../../../common/components/ContentAutocomplete/ContentAutocomplete';

export class RulesForm extends React.Component<RulesFormProps, RulesFormState> {
  private CONDITION_METRIC_HOURS_OPTION = { label: 'hour(s)', value: 'h' };
  private CONDITION_METRIC_DAYS_OPTION =  { label: 'day(s)', value: 'd' };
  private conditionValueValidators = [ regEx(CONDITION_VALUE_REGULAR_EXPRESSION, 'Please provide a valid number.'), min(1), max(100) ];
   

  constructor(props: any) {
    super(props);

    if (this.props.rule) {
      const rule = this.props.rule;
      let conditionValue;
      let conditionMetric;

      if (rule.conditionValue) {
        const lastIndex = rule.conditionValue.length - 1;
        conditionValue = rule.conditionValue.substring(0, lastIndex);
        conditionMetric = rule.conditionValue.charAt(lastIndex);
      }

      this.state = {
        contentItem: this.props.contentItems.find(x => x.contentId === rule.contentId),
        contentItemErrorMessage: '',
        target: rule.target,
        targetErrorMessage: '',
        condition: rule.condition,
        conditionValue: conditionValue || '',
        conditionValueErrorMessage: '',
        conditionMetric: conditionMetric || this.CONDITION_METRIC_HOURS_OPTION.value,
        lockInput: false
      };
    } else {
      this.state = {
        contentItemErrorMessage: '',
        targetErrorMessage: '',
        conditionValueErrorMessage: '',
        conditionMetric: this.CONDITION_METRIC_HOURS_OPTION.value,
        lockInput: false
      };
    }

    
    this.setContent = this.setContent.bind(this);
    this.onTargetSelection = this.onTargetSelection.bind(this);
    this.onConditionSelection = this.onConditionSelection.bind(this);
    this.handleConditionValueInput = this.handleConditionValueInput.bind(this);
    this.postRule = this.postRule.bind(this);
  }

  setContent(selection: { label: string, value: UserContent } | null) {
    const newState: Partial<RulesFormState> = {
      target: undefined,
      targetErrorMessage: '',
      condition: undefined,
      conditionValue: undefined,
      conditionValueErrorMessage: ''
    };

    newState.contentItem = selection ? selection.value : undefined;
    newState.contentItemErrorMessage = selection ? '' : 'Please select a content source.';

    this.setState(newState as RulesFormState);
  }

  onTargetSelection(selection: { label: string, value: string } | null) {
    const newState: Partial<RulesFormState> = {
      target: selection ? selection.value as ContentTarget : undefined,
      targetErrorMessage: selection ? '' : 'Please select content target.',
      condition: undefined,
      conditionValue: undefined,
      conditionValueErrorMessage: ''
    };

    this.setState(newState as RulesFormState);
  }

  onConditionSelection(selection: { label: string, value: string } | null) {
    if (selection) {
      this.setState({ condition: selection.value as TargetCondition });
    } else {
      this.setState({ condition: undefined });
    }
  }

  handleConditionValueInput(value: string) {
    const errorMessage = validateInput(value, this.conditionValueValidators);

    if (errorMessage) {
      this.setState({ conditionValue: value, conditionValueErrorMessage: errorMessage });
    } else {
      this.setState({ conditionValue: value, conditionValueErrorMessage: errorMessage });
    }
  }

  get formInvalid(): boolean {
    return !this.state.contentItem
      || !!this.state.contentItemErrorMessage
      || (this.state.contentItem && this.state.contentItem.contentType !== ContentType.URL && !this.state.target)
      || !!this.state.targetErrorMessage
      || (this.state.condition === TargetCondition.PUBLISHED_WITHIN && (!this.state.conditionValue || !!this.state.conditionValueErrorMessage));
  }

  get editFormValuesChanged(): boolean {
    const prev = this.props.rule;
    const newCondition = `${this.state.conditionValue ? this.state.conditionValue : ''}${this.state.conditionMetric}`;
    return prev?.contentId !== this.state.contentItem?.contentId
      || prev?.target !== this.state.target
      || prev?.condition !== this.state.condition
      || (this.state.condition === TargetCondition.PUBLISHED_WITHIN && prev?.conditionValue !== newCondition);
  }

  postRule() {
    this.setState({ lockInput: true });
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    const requestBody: any = {
      contentId: this.state.contentItem?.contentId,
      target: this.state.target,
      order: this.props.newRuleOrder
    };

    if (this.state.condition) {
      requestBody['condition'] = this.state.condition;
    }

    if (this.state.conditionValue) {
      requestBody['conditionValue'] = this.state.conditionValue + this.state.conditionMetric;
    }

    axios.post(`users/${username}/rules`, requestBody).then((response: AxiosResponse) => {
      this.setState({
        contentItem: undefined,
        target: undefined,
        condition: undefined,
        conditionValue: '',
        lockInput: false
      });

      this.props.onRuleProvisioned(response.data as UserRule);
    }, (error: AxiosError) => {
      console.log(error);
      this.setState({ lockInput: false });
    });
  }

  patchRule() {
    this.setState({ lockInput: true });
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    const requestBody: JSONPatchOperation[] = [];

    const prev = this.props.rule;
    if (prev?.contentId !== this.state.contentItem?.contentId) {
      requestBody.push({
        op: 'replace',
        path: '/contentId',
        value: this.state.contentItem?.contentId
      });
    }

    if (prev?.target !== this.state.target) {
      requestBody.push({
        op: 'replace',
        path: '/target',
        value: this.state.target || null
      });
    }

    if ((prev?.condition || this.state.condition) && prev?.condition !== this.state.condition) {
      requestBody.push({
        op: 'replace',
        path: '/condition',
        value: this.state.condition || null
      });

      if (this.state.condition !== TargetCondition.PUBLISHED_WITHIN && prev?.conditionValue) {
        requestBody.push({
          op: 'replace',
          path: '/conditionValue',
          value: null
        });
      }
    }

    if (prev?.conditionValue || this.state.conditionValue) {
      const newConditionValue = this.state.conditionValue ? this.state.conditionValue + this.state.conditionMetric : null;

      if (prev?.conditionValue !== newConditionValue) {
        requestBody.push({
          op: 'replace',
          path: '/conditionValue',
          value: newConditionValue
        });
      }
    }

    axios.patch(`users/${username}/rules/${prev?.ruleId}`, requestBody).then((response: AxiosResponse) => {
      this.props.onRuleProvisioned(response.data as UserRule);
      this.setState({ lockInput: false });
    }, (error: AxiosError) => {
      this.setState({ lockInput: false });
      console.log(error);
    });
  }

  render() {
    let contentTargets;
    let targetConditions;
    let conditionInputsContainer;

    if (this.state.contentItem) {
      contentTargets = Object.keys(ContentTarget)
        .filter(getContentTargetFilterForContentType(this.state.contentItem.contentType))
        .map((x) => { return { label: contentTargetToLabel(x, this.state.contentItem?.contentType), value: x }; });

      if (this.state.target) {
        targetConditions = Object.keys(TargetCondition)
          .filter(getTargetConditionFilterForContentTypeAndTarget(this.state.contentItem.contentType, this.state.target))
          .map((x) => { return { label: targetConditionToLabel(x, this.state.contentItem?.contentType), value: x }; });
      }

      if (this.state.condition && this.state.condition === TargetCondition.PUBLISHED_WITHIN) {
        conditionInputsContainer = 
        <div className='condition-inputs-container'>
          <TextField
            id={this.props.rule ? 'rfc-edit-rule-condition-value-input' : 'rfc-add-rule-condition-value-input'}
            variant='outlined'
            className='condition-value-input rules-form-input-field'
            value={this.state.conditionValue || ''}
            error={!!this.state.conditionValueErrorMessage}
            disabled={this.state.lockInput}
            onChange={(event) => void this.handleConditionValueInput(event.target.value)}
            helperText={this.state.conditionValueErrorMessage || REQUIRED_FIELD_TEXT} />
          <Autocomplete
            disablePortal
            id={this.props.rule ? 'rfc-edit-rule-condition-metric-autocomplete' : 'rfc-add-rule-condition-metric-autocomplete'}
            className='condition-metric-autocomplete rules-form-input-field'
            disableClearable={true}
            options={[this.CONDITION_METRIC_HOURS_OPTION, this.CONDITION_METRIC_DAYS_OPTION]}
            disabled={this.state.lockInput}
            value={this.state.conditionMetric === this.CONDITION_METRIC_HOURS_OPTION.value
              ? this.CONDITION_METRIC_HOURS_OPTION
              : this.CONDITION_METRIC_DAYS_OPTION}
            isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
            onChange={(event, value) => { this.setState({ conditionMetric: value.value }); }}
            renderInput={(params) => <TextField {...params}/>}/>
        </div>;
      }
    }

    return (
      <div className='rules-form-container'>
        <ContentAutocomplete
          id={this.props.rule ? 'rfc-edit-rule-content-item-autocomplete' : 'rfc-add-rule-content-item-autocomplete'}
          className='rules-form-input-field'
          selectedContentItem={this.state.contentItem}
          errorMessage={this.state.contentItemErrorMessage}
          contentItems={this.props.contentItems}
          lockInput={this.state.lockInput}
          onContentSelection={(value) => this.setContent(value)}/>

        {
          contentTargets && contentTargets.length
            ?
            <Autocomplete
              disablePortal
              id={this.props.rule ? 'rfc-edit-rule-target-content-autocomplete' : 'rfc-add-rule-target-content-autocomplete'}
              className='target-content-autocomplete rules-form-input-field'
              options={contentTargets}
              disabled={this.state.lockInput}
              value={this.state.target
                ? { label: contentTargetToLabel(this.state.target, this.state.contentItem?.contentType), value: this.state.target }
                : null }
              isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
              onChange={(event, value) => { this.onTargetSelection(value); }}
              renderInput={(params) => <TextField {...params}
                label="Target*"
                error={!!this.state.targetErrorMessage}
                helperText={this.state.targetErrorMessage || REQUIRED_FIELD_TEXT} />}/>
            : <></>
        }

        {
          targetConditions && targetConditions.length
            ?
            <Autocomplete
              disablePortal
              id={this.props.rule ? 'rfc-edit-rule-target-condition-autocomplete' : 'rfc-add-rule-target-condition-autocomplete'}
              className='target-condition-autocomplete rules-form-input-field'
              options={targetConditions}
              disabled={this.state.lockInput}
              value={this.state.condition
                ? { label: targetConditionToLabel(this.state.condition, this.state.contentItem?.contentType), value: this.state.condition }
                : null }
              isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
              onChange={(event, value) => { this.onConditionSelection(value); }}
              renderInput={(params) => <TextField {...params}
                label="If"/>}/>
            : <></>
        }

        { conditionInputsContainer }
        
        <div className='save-rule-btn-container'>
          <Button className='save-rule-btn'
            disabled={this.formInvalid || this.state.lockInput || (!!this.props.rule && !this.editFormValuesChanged)}
            variant="contained"
            onClick={() => { this.props.rule ? this.patchRule() : this.postRule(); }}>
            { this.state.lockInput ? <LoadingSpinner size={15}/> : this.props.rule ? 'Save Rule' : 'Add Rule'}
          </Button>
        </div>
      </div>
    );
  }
}
