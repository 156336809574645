import React from 'react';
import ReactDOM from 'react-dom';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { AuthorizeProps } from './authorize-props';
import { AccountType, accountTypeToLabel } from '../../models/authorization/enum/account-type';
import AppContext from '../../common/context/AppContext';
import './Authorize.scss';
import { AuthorizeState } from './authorize-state';
import { LoadingOverlay } from '../../common/components/LoadingOverlay/LoadingOverlay';
import AxiosSingleton from '../../common/web/axios-singleton';
import { USERNAME } from '../../common/cookies';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/check_circle.svg';
import { ReactComponent as RadioButtonUnchecked } from '../../assets/icons/radio_button_unchecked.svg';
import { AxiosInstance, AxiosResponse } from 'axios';
import Button from '@mui/material/Button';
import { ContentType } from '../../models/user-content/enum/content-type';
import { ConfirmModal } from '../../common/components/ConfirmModal/ConfirmModal';
import { CONTENT_ROUTE } from '../../common/app-constants';

export const AuthorizeWrapper = () => {
  const params = useParams();
  const searchParams = useSearchParams()[0];

  return (<Authorize accountType={AccountType[params.accountType as keyof typeof AccountType]} code={searchParams.get('code') || ''} />);
};

class Authorize extends React.Component<AuthorizeProps, AuthorizeState> {
  private ERROR_MESSAGE: string;

  constructor(props: any) {
    super(props);

    this.state = {
      showLoadingOverlay: true,
      showModal: false,
      navigateToContent: !this.props.accountType || !this.props.code
    };

    this.getEntityName = this.getEntityName.bind(this);
    this.addSelectedContent = this.addSelectedContent.bind(this);
    this.ERROR_MESSAGE = `An error occurred while authorizing your ${accountTypeToLabel(this.props.accountType)} account. Please try again later.`;
  }
  
  postContent(axios: AxiosInstance, username: string, requestBody: any, successCallback: () => void, failureCallback: () => void) {
    axios.post(`users/${username}/content`, requestBody).then(() => {
      successCallback();
    }, () => {
      failureCallback();
    });
  }

  componentDidMount(): void {
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    const request: { code?: string, accountType: AccountType } = { accountType: this.props.accountType };

    if (this.props.accountType === AccountType.INSTAGRAM) {
      request.code = this.props.code.endsWith('#_')
        ? this.props.code.slice(0, this.props.code.length - 2)
        : this.props.code;
    } else {
      request.code = this.props.code;
    }

    if (username && !this.state.navigateToContent) {
      axios.post(`users/${username}/authorization`, request).then((response: AxiosResponse) => {
        if (this.props.accountType === AccountType.PATREON) {
          this.setState({ showLoadingOverlay: false, authorizationID: response.data.externalID, entities: response.data.embedded.map((x:any) => { return { selected: false, entity: x }; }) });
        } else {
          const requestBody = {
            contentType: ContentType.TIKTOK_ACCOUNT,
            externalId: response.data.embedded[0].externalID,
            label: response.data.embedded[0].label,
            url: response.data.embedded[0].url
          };

          this.postContent(
            axios,
            username,
            requestBody,
            () => this.setState({ navigateToContent: true }),
            () => this.setState({ showLoadingOverlay: false, showModal: true, modalText: this.ERROR_MESSAGE })
          );
        }
      }, () => {
        this.setState({ showLoadingOverlay: false, showModal: true, modalText: this.ERROR_MESSAGE });
      });
    }
  }

  private getEntityName(count: number) {
    if (this.props.accountType === AccountType.PATREON) {
      if (count > 1) {
        return 'campaigns';
      } else {
        return 'campaign';
      }
    } else {
      if (count > 1) {
        return 'entities';
      } else {
        return 'entity';
      }
    }
  }

  private getContentType() {
    if (this.props.accountType === AccountType.PATREON) {
      return ContentType.PATREON_CAMPAIGN;
    }
  }

  addSelectedContent() {
    this.setState({ showLoadingOverlay: true });
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    if (this.state.entities) {
      const addContentRequests = this.state.entities
        .filter(x => x.selected)
        .map(x => {
          const request = {
            authorizationID: this.state.authorizationID,
            contentType: this.getContentType(),
            label: x.entity.label,
            externalId: x.entity.externalID
          };

          return axios.post(`users/${username}/content`, request);
        });

      Promise.allSettled(addContentRequests).then((results) => {
        const newState: Partial<AuthorizeState> = {showLoadingOverlay: false, showModal: true};
        const rejectedCount = results.filter(x => x.status === 'rejected').length;
        if (rejectedCount === results.length) {
          newState.modalText = `An error occurred while adding your ${this.getEntityName(addContentRequests.length)}. Please try again later.`;
        } else if (rejectedCount > 0) {
          newState.modalText = `An error occurred while adding at least one of your ${this.getEntityName(addContentRequests.length)}. Please try again later.`;
        } else {
          newState.modalText = `Your ${this.getEntityName(addContentRequests.length)} ${addContentRequests.length > 1 ? 'were' : 'was'} successfully added!`;
        }

        this.setState(newState as AuthorizeState);
      });
    }
  }

  render() {
    const entityElements = this.state.entities && this.state.entities.length
      ?
      this.state.entities.map((x, i) => {
        return (
          <div
            className='authorize-entity'
            key={x.entity.externalID}
            onClick={() => {
              const entities = this.state.entities || [];
              const entity = entities[i];
              entity.selected = !entity.selected;
              this.setState({ entities: entities });
            }}>
            { x.selected ? <CheckCircleIcon className='green'/> : <RadioButtonUnchecked /> }
            <span>{ x.entity.label }</span>
          </div>
        );
      })
      : undefined;

    const buttonDisabled = !this.state.entities || !this.state.entities.some(x => x.selected);

    return (
      <AppContext.Consumer>
        {
          ctx => (
            !ctx.isLoggedIn
              ? <Navigate to='/login'/>
              :
              <div id="auc-authorize-container" className='mobile-drawer-offset'>
                {
                  this.state.navigateToContent
                    ? <Navigate to={CONTENT_ROUTE}/>
                    : undefined
                }
                {
                  this.state.showLoadingOverlay
                    ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
                    : undefined
                }
                {
                  this.state.showModal
                    ? ReactDOM.createPortal(
                      <ConfirmModal
                        message={this.state.modalText || 'An error occurred.'}
                        confirmText={'Ok'}
                        onClose={() => {
                          this.setState({showModal: false, navigateToContent: true});
                        }} />,
                      document.getElementById('modal-portal-container') as HTMLElement)
                    : undefined
                }
                {
                  entityElements && entityElements.length
                    ?
                    <div>
                      <h2>Select the {accountTypeToLabel(this.props.accountType)} {this.getEntityName(this.state.entities?.length || 1)} that you would like to add.</h2>
                      <div id="auc-entities">
                        {entityElements}
                      </div>
                      <div id="auc-add-btn-container">
                        <Button className='add-btn'
                          disabled={buttonDisabled}
                          variant='contained'
                          onClick={() => this.addSelectedContent()}>
                          Add
                        </Button>
                      </div>
                    </div>
                    : undefined
                }
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}
