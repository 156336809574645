import React, { CSSProperties } from 'react';
import './RoutePagePreview.scss';
import { RoutePagePreviewProps } from './route-page-preview-props';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { RoutePageStyling } from '../../../models/user-route-page/route-page-styling';
import { USERNAME } from '../../../common/cookies';
import { Icon, getRoutePageItemIcon } from '../../../models/user-content/enum/icon';
import { getContentItemLabel } from '../../../models/user-content/enum/content-type';

export class RoutePagePreview extends React.Component<RoutePagePreviewProps> {
  private styling: RoutePageStyling;

  constructor(props: RoutePagePreviewProps) {
    super(props);

    const storageItem = sessionStorage.getItem(this.props.routePageStylingKey);

    if (storageItem) {
      this.styling = JSON.parse(storageItem);
    } else {
      this.styling = {
        mainContainer: {
          width: '100%'
        },
        displayName: {
          width: '100%',
          textAlign: 'center'
        },
      
        link: {
          padding: '0 8px 0',
          display: 'flex',
          justifyItems: 'flex-start',
          margin: '25px auto',
          height: '75px',
          maxWidth: '500px',
          width: '90%',
          borderStyle: 'solid',
          borderWidth: 'thin',
          borderRadius: '10px',
          overflow: 'hidden',
          position: 'relative',
      
          icon: {
            marginTop: '16px',
            width: '25%',
            maxWidth: '35px',
            height: '35px',
            display: 'block'
          },
      
          label: {
            width: 'calc(100% - 16px)',
            display: 'block',
            position: 'absolute',
            top: '24px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold'
          }
        }
      };
    }
  }

  render() {
    const mainContainerStyles: CSSProperties = this.styling.mainContainer;
    mainContainerStyles.backgroundColor = this.props.routePage?.backgroundColor;

    const displayNameStyles: CSSProperties = this.styling.displayName;
    displayNameStyles.marginTop = '30px';
    displayNameStyles.color = this.props.routePage?.headerTextColor || 'black';

    const linkStyles: CSSProperties = this.styling.link;
    linkStyles.backgroundColor = this.props.routePage?.buttonColor;
    linkStyles.color = this.props.routePage?.textColor;
    linkStyles.borderColor = this.props.routePage?.buttonAccentColor || 'white';

    const linkIconStyles: CSSProperties = this.styling.link.icon;
    linkIconStyles.color = this.props.routePage?.textColor || 'black';

    const linkLabelStyles: CSSProperties = this.styling.link.label;
    linkLabelStyles.color = this.props.routePage?.textColor || 'black';

    const links = this.props.items.flatMap(x => {
      return x.content
        ?
        [(
          <a key={x.link.routePageItemID} style={linkStyles} href={x.content?.url || x.content?.resolvedURL} target="_blank" rel="noreferrer">
            <span style={linkIconStyles}>
              { getRoutePageItemIcon(x.content?.icon || Icon.LINK) }
            </span>
            <span style={linkLabelStyles}>
              { x.link.label || getContentItemLabel(x.content) }
            </span>
          </a>
        )]
        : [];
    });

    return (
      <div id="rppc-overlay">
        <div id="rppc-preview-container">
          <div id="rppc-taskbar">
            <span id="rppc-url">
              { this.props.url }
            </span>
            <div id="rppc-button-container">
              <IconButton id="rppc-close-btn" className='taskbar-btn' onClick={() => this.props.onPreviewClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div id="rppc-browser-page" style={{ backgroundColor: this.props.routePage?.backgroundColor, overflowY: 'scroll' }}>
            <h1 id="rppc-display-name" style={displayNameStyles}>{this.props.displayName || localStorage.getItem(USERNAME)}</h1>
            <div id="rppc-main-container" style={mainContainerStyles}>
              { links }
            </div>
          </div>
        </div>
      </div>
    );
  }
}