import React from 'react';
import './LoginHelp.scss';
import Button from '@mui/material/Button';
import { LoginHelpType } from './login-help-type';
import { LoginHelpState } from './login-help-state';
import { LoginHelpAction } from './LoginHelpAction/LoginHelpAction';
import AppContext from '../../common/context/AppContext';
import { Navigate } from 'react-router-dom';
import { CONTENT_ROUTE } from '../../common/app-constants';

export class LoginHelp extends React.Component<any, LoginHelpState> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <AppContext.Consumer>
        {
          ctx => (
            ctx.isLoggedIn
              ? <Navigate to={CONTENT_ROUTE}/>
              :
              <div id="lhc-login-help-container">
                {
                  !this.state.requestType
                    ?
                    <div id="lhc-step-1">
                      <Button id="lhc-forgot-username-btn"
                        className='login-help-btn'
                        variant="contained"
                        onClick={() => void this.setState({ requestType: LoginHelpType.FORGOT_USERNAME })}>
                        Forgot username
                      </Button>
                      <Button id="lhc-forgot-password-btn"
                        className='login-help-btn'
                        variant="contained"
                        onClick={() => void this.setState({ requestType: LoginHelpType.RESET_PASSWORD })}>
                        Forgot password
                      </Button>
                    </div>
                    :
                    <LoginHelpAction
                      requestType={this.state.requestType}
                      onGoBackClicked={() => void this.setState({ requestType: undefined })}/>
                }
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}