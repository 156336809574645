import Button from '@mui/material/Button';
import { AxiosError } from 'axios';
import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { ConfirmModal } from '../../../common/components/ConfirmModal/ConfirmModal';
import { LoadingOverlay } from '../../../common/components/LoadingOverlay/LoadingOverlay';
import ContentContext from '../../../common/context/ContentContext';
import { USERNAME } from '../../../common/cookies';
import AxiosSingleton from '../../../common/web/axios-singleton';
import { contentTargetToLabel } from '../../../models/user-rule/enum/content-target';
import { targetConditionToLabel } from '../../../models/user-rule/enum/target-condition';
import { UserRule } from '../../../models/user-rule/user-rule';
import { RulesForm } from '../common/RulesForm/RulesForm';
import { RuleProps } from './rule-props';
import { RuleState } from './rule-state';
import './Rule.scss';
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/down_arrow.svg';
import { ReactComponent as DangerousIcon } from '../../../assets/icons/dangerous.svg';
import { ReactComponent as DragHandleIcon } from '../../../assets/icons/drag_handle.svg';
import { ReactComponent as MoreHorizIcon } from '../../../assets/icons/more_horiz.svg';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getContentItemLabel, getContentTypeIcon } from '../../../models/user-content/enum/content-type';

export class Rule extends React.Component<RuleProps, RuleState> {
  constructor(props: RuleProps) {
    super(props);

    this.state = {
      showEditForm: false,
      rule: this.props.item as UserRule,
      contentItem: this.props.contentItem,
      showOverlay: false,
      showDeleteModal: false,
      showMobileMenu: false
    };

    this.parseConditionValue = this.parseConditionValue.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  parseConditionValue(value: string) {
    const metricCode = value.charAt(value.length - 1);
    const number = parseInt(value.replace(metricCode, ''), 10);
    const metric = metricCode === 'h' ? 'hour' : 'day';

    return `${number != 1 ? number : ''} ${metric}${number > 1 ? 's' : ''}`;
  }

  handleDeleteModalClose(confirmed: boolean) {
    this.setState({ showDeleteModal: false });

    if (confirmed) {
      this.setState({ showOverlay: true });

      const axios = AxiosSingleton.get();
      const username = localStorage.getItem(USERNAME);

      axios.delete(`users/${username}/rules/${this.state.rule.ruleId}`).then(() => {
        this.setState({ showOverlay: false });
        if (this.props.onDelete) {
          this.props.onDelete(this.state.rule.ruleId);
        }
      }, (error: AxiosError) => {
        this.setState({ showOverlay: false });
        console.log(error);
      });
    }
  }

  toggleMobileMenu(event?: React.MouseEvent<HTMLButtonElement>, callback?: () => void) {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
    if (event) {
      this.setState({ mobileMenuAnchorElement: event.currentTarget });
    }

    if (callback) {
      callback();
    }
  }

  render() {
    const style: CSSProperties = {
      opacity: this.props.followsDeadEndRule && !this.state.showEditForm ? '35%' : undefined
    };

    return (
      <div className='rule-parent-container'>
        <div style={{...style}} ref={this.props.moveableRef} className={`rule-item-container${this.props.isDragging ? ' dragging' : ''}`}>
          {
            this.state.showOverlay
              ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
              : <></>
          }
          {
            this.state.showDeleteModal
              ? ReactDOM.createPortal(
                <ConfirmModal
                  message='Are you sure you want to delete this rule?'
                  confirmText='Yes'
                  declineText='No'
                  onClose={(confirmed) => { this.handleDeleteModalClose(confirmed); }} />,
                document.getElementById('modal-portal-container') as HTMLElement)
              : <></>
          }
          <div className='rule-item-btn-container'>
            <DragHandleIcon />
            <Button className='edit-rule-btn desktop' onClick={() => this.setState({ showEditForm: !this.state.showEditForm })}>
              { this.state.showEditForm ? 'Cancel' : 'Edit' }
            </Button>
            {
              this.state.showEditForm
                ? <></>
                :
                <Button className='delete-rule-btn desktop' onClick={() => this.setState({ showDeleteModal: true })}>
                  Delete
                </Button>
            }
            <Button
              className='mobile'
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (!this.state.showEditForm) {
                  this.toggleMobileMenu(event);
                } else {
                  this.setState({ showEditForm: false });
                }
              }}>
              { this.state.showEditForm ? 'Cancel' : <MoreHorizIcon /> }
            </Button>
            <Menu
              anchorEl={this.state.mobileMenuAnchorElement}              
              className='mobile'
              open={this.state.showMobileMenu}
              onClose={() => void this.toggleMobileMenu()}>
              <MenuItem onClick={() => void this.toggleMobileMenu(undefined, () => void this.setState({ showEditForm: true }))}>
                Edit
              </MenuItem>
              <MenuItem onClick={() =>  void this.toggleMobileMenu(undefined, () => void this.setState({ showDeleteModal: true }))}>
                Delete
              </MenuItem>
            </Menu>
          </div>
          {
            this.state.showEditForm
              ?
              <ContentContext.Consumer>
                {
                  ctx => (
                    <RulesForm
                      rule={this.state.rule}
                      contentItems={ctx.contentItems || []}
                      onRuleProvisioned={(rule: UserRule) => {
                        if (this.props.onEdit) {
                          this.props.onEdit(rule);
                        }
                        this.setState({ rule: rule, showEditForm: false, contentItem: ctx.contentItems?.find(x => x.contentId === rule.contentId) });
                      }}/>
                  )
                }
              </ContentContext.Consumer>
              : !this.state.contentItem
                ?
                <div>
                  <h3>Oops! Looks like the content for this rule has been deleted or there was an issue fetching it.</h3>
                </div>
                :
                <div>
                  <div className='rule-target-container'>
                    <span>
                      <span>
                        Route to
                      </span>
                      <span>
                        { getContentTypeIcon(this.state.contentItem?.contentType, undefined, {width: 20, height: 20, marginTop: '2.5px', marginLeft: '2px', marginRight: '2px'}) }
                      </span>
                      <a className='rule-target-label' href={this.state.contentItem ? this.state.contentItem.resolvedURL : '#'} target='_blank' rel="noreferrer">
                        { this.state.contentItem ? getContentItemLabel(this.state.contentItem) : undefined }&nbsp;
                      </a>
                      {
                        this.state.rule.target
                          ? <span className='rule-target-type'>{contentTargetToLabel(this.state.rule.target, this.state.contentItem.contentType).toLocaleLowerCase()}</span>
                          : undefined
                      }
                    </span>
                  </div>

                  {
                    this.state.rule.condition
                      ?
                      <div className='rule-condition-container'>
                        <span>if&nbsp;</span>
                        <span className='rule-condition-text'>
                          { targetConditionToLabel(this.state.rule.condition, this.state.contentItem?.contentType).toLowerCase() }
                        </span>
                        {
                          this.state.rule.conditionValue
                            ?
                            <span className='rule-condition-value'>
                              &nbsp;{ this.parseConditionValue(this.state.rule.conditionValue) }
                            </span>
                            : undefined
                        }
                      </div>
                      : <></>
                  }
                </div>
          }
        </div>
        {
          this.props.lastRule
            ? undefined
            :
            this.props.deadEndRule
              ?
              <Tooltip
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={'The rule(s) that follow the one above will never be evaluated due to its conditions or lack thereof.'}>
                <DangerousIcon className='dead-end-indicator rule-flow-icon' />
              </Tooltip>
              :
              <DownArrowIcon className={`rule-flow-icon${this.props.deadEndRule || this.props.followsDeadEndRule ? ' dead-end-arrow' : ''}`}/>
        }
      </div>
    );
  }
}
