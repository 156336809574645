import Button from '@mui/material/Button';
import { AxiosResponse } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Navigate } from 'react-router-dom';
import AppContext from '../../common/context/AppContext';
import { USERNAME } from '../../common/cookies';
import AxiosSingleton from '../../common/web/axios-singleton';
import { UserContent } from '../../models/user-content/user-content';
import { ContentForm } from './common/ContentForm/ContentForm';
import { ContentState } from './content-state';
import './Content.scss';
import { ContentItem } from './ContentItem/ContentItem';
import { LoadingOverlay } from '../../common/components/LoadingOverlay/LoadingOverlay';

export class Content extends React.Component<any, ContentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      contentItems: [],
      contentFetchComplete: false,
      showAddContent: false
    };

    this.toggleShowAddContent = this.toggleShowAddContent.bind(this);
    this.handleContentCreation = this.handleContentCreation.bind(this);
    this.handleContentDeletion = this.handleContentDeletion.bind(this);
  }

  toggleShowAddContent() {
    this.setState({ showAddContent: !this.state.showAddContent });
  }

  handleContentCreation(item?: UserContent) {
    if (item) {
      const items = this.state.contentItems;
      items.unshift(item);

      this.setState({ contentItems: items });
    } else {
      this.setState({ showAddContent: false });
    }
  }

  handleContentDeletion(contentId: string) {
    const contentItems = this.state.contentItems;

    this.setState({ 
      contentItems: contentItems.filter(x => x.contentId !== contentId)
    });
  }

  componentDidMount(): void {
    const axios = AxiosSingleton.get();
    const username = localStorage.getItem(USERNAME);

    if (username) {
      axios.get(`users/${username}/content`).then((response: AxiosResponse) => {
        if (response && response.data && response.data.embedded) {
          this.setState({ contentItems: response.data.embedded, contentFetchComplete: true });
        }
      }, () => {
        this.setState({ contentFetchComplete: true });
      });
    }
  }

  render() {
    const contentItemElements = this.state
      .contentItems
      .map(x => <ContentItem onDelete={(contentId) => { this.handleContentDeletion(contentId); }} key={x.contentId} contentItem={x} />);

    return (
      <AppContext.Consumer>
        {
          ctx => (
            !ctx.isLoggedIn
              ? <Navigate to='/login'/>
              :
              <div id="cc-content-container" className='mobile-drawer-offset'>
                {
                  !this.state.contentFetchComplete
                    ? ReactDOM.createPortal(<LoadingOverlay />, document.getElementById('overlay-portal-container') as HTMLElement)
                    : <></>
                }
                <h1>These are your content sources</h1>
                <Button id="cc-show-add-content-btn" variant="outlined" onClick={this.toggleShowAddContent}>{ this.state.showAddContent ? 'Cancel' : 'Add' }</Button>
                { this.state.showAddContent
                  ? <ContentForm contentItem={undefined} onContentItemProvisioned={(contentItem?: UserContent) => { this.handleContentCreation(contentItem); }}/>
                  : <></> }
                <div id="cc-content-items-container">
                  {
                    this.state.contentItems && this.state.contentItems.length
                      ? contentItemElements
                      : this.state.contentFetchComplete
                        ?
                        <h2 id="cc-helpful-message">
                          Get started by adding your content sources with the &quot;Add&quot; button above! 
                        </h2>
                        : undefined
                  }
                </div>
              </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}
