import React from 'react';
import { LoadingSpinnerProps } from './loading-spinner-props';
import './LoadingSpinner.scss';

export class LoadingSpinner extends React.Component<LoadingSpinnerProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const containerSize = this.props.size ? Math.ceil(Math.sqrt(2 * Math.pow(this.props.size, 2))).toString() + 'px' : '51px';
    const size = this.props.size? this.props.size.toString() + 'px' : '36px';
    const styles = {
      height: size,
      width: size,
      borderWidth: '2px'
    };

    return (
      <div className='lsc-loading-spinner-container' style={{width: containerSize, height: containerSize}}>
        <div style={styles}></div>
        <div style={styles}></div>
        <div style={styles}></div>
        <div style={styles}></div>
      </div>
    );
  }
}
